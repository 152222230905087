import { Button as AntButtton } from "antd";
import React from "react";

const CustomButton = ({ children, style, ...rest }: any) => {
  const primaryColor = localStorage.getItem("primaryColor");

  return (
    <AntButtton style={{ ...style, backgroundColor: primaryColor, borderColor: primaryColor }} {...rest}>
      {children}
    </AntButtton>
  );
};

export default CustomButton;
