import React from "react";

export const Activities = React.lazy(() => import("./pages/Activities"));
export const Bin = React.lazy(() => import("./pages/Bin"));
export const CreateSurvey = React.lazy(() => import("./pages/CreateSurvey"));
export const Dashboard = React.lazy(() => import("./pages/Dashboard"));
export const FeedItemForm = React.lazy(() => import("./pages/FeedItemForm"));
export const FileEditor = React.lazy(() => import("./pages/FileEditor"));
export const FeedItemView = React.lazy(() => import("./pages/FeedItemView"));
export const GroupDetail = React.lazy(() => import("./pages/GroupDetail"));
export const GroupEditForm = React.lazy(() => import("./pages/GroupEditForm"));
export const Groups = React.lazy(() => import("./pages/Groups"));
export const ImportContentForm = React.lazy(() => import("./pages/GroupImportContentForm"));
export const GroupRequests = React.lazy(() => import("./pages/GroupRequests"));
export const UploadContentForm = React.lazy(() => import("./pages/GroupUploadContentForm"));
export const Login = React.lazy(() => import("./pages/Login"));
export const ManageUsers = React.lazy(() => import("./pages/ManageUsers"));
export const Meetings = React.lazy(() => import("./pages/Meetings"));
export const Reset = React.lazy(() => import("./pages/Reset"));
export const Settings = React.lazy(() => import("./pages/Settings"));
export const SSOLogin = React.lazy(() => import("./pages/SSOLogin"));
export const Tags = React.lazy(() => import("./pages/Tags"));
export const Verify = React.lazy(() => import("./pages/Verify"));
export const JoinMeeting = React.lazy(() => import("./pages/JoinMeeting"));
export const JoinMeetingComponent = React.lazy(() => import("./components/JoinMeetingComponent"));
