import { UploadOutlined } from "@ant-design/icons";
import feathers from "@feathersjs/client";
import rest from "@feathersjs/rest-client";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Select,
  Table,
  Tag,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { BASE_URL } from "../constants";
import PageTitle from "../shared/PageTitle";
import { SearchBox } from "../shared/UtilComponents/SearchBox";
import { loadingAction } from "../store/Actions";
import { FetchTags, FetchUsers } from "../store/Effects";
import { AppStateType, IUserModel } from "../types";
import APIProvider from "../utils/APIProvider";
import usersFromCSV from "../utils/usersFromCSV";

const ManageUsers: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [usersData, setUsersData] = useState<IUserModel[]>([]);
  const [importedUsers, setImportedUsers] = useState<any[]>([]);
  const app = feathers();
  // Connect to a different URL
  const restClient = rest(BASE_URL);

  // Configure an AJAX library (see below) with that client
  app.configure(restClient.fetch(window.fetch.bind(window)));
  const importUsersService = app.service("import-users");
  const createUsersService = app.service("create-users");

  const [form] = Form.useForm<any>();

  const initialValues: any = { role: "owner" };

  const dispatch = useDispatch();

  const newUsers = useMemo(() => {
    return importedUsers.map((data) => {
      let [firstName, lastName, phone, email, username] = data;
      return {
        firstName,
        lastName,
        email,
        phone,
        username,
      };
    });
  }, [importedUsers]);

  useEffect(() => {
    dispatch(FetchUsers());
    dispatch(FetchTags());
  }, [dispatch]);

  const users = useSelector(
    (state: AppStateType) => state.mainStore.users,
    shallowEqual
  );
  const tags = useSelector(
    (state: AppStateType) => state.mainStore.tags,
    shallowEqual
  );
  const isSuperAdmin = useSelector(
    (state: AppStateType) => state.mainStore.isSuperAdmin,
    shallowEqual
  );

  useEffect(() => {
    form.setFieldsValue(selectedUser ? selectedUser : { role: "owner" });
  }, [form, selectedUser]);

  useEffect(() => {
    setUsersData(users);
  }, [users]);
  let configurations = useSelector(
    (state: AppStateType) => state.mainStore.configurations,
    shallowEqual
  );

  const columns = [
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      width: "120px",
      render: (createdAt: string) => (
        <span> {dayjs(createdAt).format("MMM DD, YYYY")} </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "150px",
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: "150px",
      render: (username: string) => <span> {username || "-"} </span>,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      key: "firstName",
      width: "100px",
      render: (_: any, record: any) => (
        <span>
          {record.firstName} {record.lastName}{" "}
        </span>
      ),
    },
    {
      title: "Type",
      dataIndex: "objectId",
      key: "objectId",
      width: "75px",
      render: (objectId: string) => (
        <span>
          <Tag color={objectId ? "blue" : "default"}>
            {objectId ? "o365" : "Local"}
          </Tag>
        </span>
      ),
      filters: [
        {
          text: "Is o365",
          value: "o365",
        },
        {
          text: "Is Local",
          value: "local",
        },
      ],
      onFilter: (value: any, record: IUserModel) => {
        return value === (record.objectId ? "o365" : "local");
      },
    },
    {
      title: "Roles",
      dataIndex: "role",
      key: "roles",
      width: "75px",
      render: (roles: string[]) => {
        return (
          <span>
            {roles.includes("super-admin")
              ? "Super Admin"
              : roles.includes("admin")
              ? "Admin"
              : "User"}{" "}
          </span>
        );
      },
      filters: [
        {
          text: "Super Admin",
          value: "super-admin",
        },
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "User",
          value: "user",
        },
      ],
      onFilter: (value: any, record: IUserModel) => {
        let { role } = { ...record };
        if (role !== "admin" && role !== "super-admin") {
          role = "user";
        }
        return value === role;
      },
    },
    {
      title: "Action",
      key: "action",
      width: "75px",
      render: (_: any, record: any) => (
        <div style={{ whiteSpace: "nowrap" }}>
          <Button
            className="mr-2"
            type="primary"
            // disabled={record.superAdmin}
            onClick={() => {
              setSelectedUser(record);
              setShowUserModal(true);
            }}
          >
            Edit
          </Button>

          <Button
            // disabled={record.username === "admin"}
            type="default"
            danger
            onClick={() => {
              if (record.role === "super-admin") {
                return Modal.info({
                  title: "Delete User",
                  content: (
                    <span>
                      Please demote{" "}
                      <strong>
                        {record?.firstName} {record?.lastName}
                      </strong>{" "}
                      first and then try again.
                    </span>
                  ),
                  okText: "Close",
                  okButtonProps: {
                    htmlType: "button",
                  },
                  onCancel: () => {},
                  onOk: () => {},
                });
              }
              setSelectedUser(record);
              setShowDeleteModal(true);
            }}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleSearch = (searchText: string) => {
    const filteredEvents = users.filter(
      ({ firstName, lastName, email, username }) => {
        let name = `${firstName} ${lastName}`;
        name = name?.toLowerCase();
        searchText = searchText?.toLowerCase();
        return (
          name?.includes(searchText ? searchText.trim() : "") ||
          username
            ?.toLowerCase()
            ?.includes(searchText ? searchText.trim() : "") ||
          email?.toLowerCase()?.includes(searchText ? searchText.trim() : "")
        );
      }
    );
    setUsersData(filteredEvents);
  };

  const createOrUpdateUser = (values: any, continueAdd: boolean) => {
    dispatch(loadingAction(true));
    createUsersService
      .create({ ...values, continueAdd })
      .then((res: any) => {
        dispatch(FetchUsers());
      })
      .catch((err: any) => {
        dispatch(loadingAction(false));
        if (err.message.includes("already exist as an external member")) {
          return Modal.confirm({
            title: "Update User",
            content: `User already exist as an external member. To promote as an internal user, please confirm.`,
            okText: "Confirm",
            okButtonProps: {
              htmlType: "button",
            },
            onCancel: () => {
              dispatch(loadingAction(false));
            },
            onOk: () => {
              createUsersService
                .create({ ...values, continueAdd: true })
                .then((res: any) => dispatch(FetchUsers()))
                .catch((err: any) => console.log("err", err));
            },
          });
        } else if (err.message.includes("User recently deleted")) {
          return Modal.confirm({
            title: "Update User",
            content: `User recently deleted. Please confirm to reinstate.`,
            okText: "Confirm",
            okButtonProps: {
              htmlType: "button",
            },
            onCancel: () => {
              dispatch(loadingAction(false));
            },
            onOk: () => {
              createUsersService
                .create({ ...values, continueAdd: true })
                .then((res: any) => dispatch(FetchUsers()))
                .catch((err: any) => console.log("err", err));
            },
          });
        } else {
          message.error(err.message);
        }
      });
  };

  const removeUser = () => {
    dispatch(loadingAction(true));
    createUsersService
      .remove(selectedUser._id)
      .then(async (res: any) => {
        const { data: memberOfTheGroups } = await APIProvider.get(
          `group-members?user=${selectedUser._id}`
        );
        for (let i = 0; i < memberOfTheGroups.data.length; i++) {
          const memberOfTheGroup = memberOfTheGroups.data[i];
          await APIProvider.delete(`group-members/${memberOfTheGroup._id}`);
        }
        setSelectedUser(null);
        setShowDeleteModal(false);
        form.resetFields();
        dispatch(FetchUsers());
      })
      .catch(async (err: any) => {
        dispatch(loadingAction(false));
        message.error("something went wrong while removing the user");
      });
  };

  const [importFile, setImportFile] = useState<any>({});
  const [showImportModal, setShowImportModal] = useState(false);
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <div>
      <PageTitle
        heading="Manage Users"
        showCreateAction={true}
        createActionLabel={"Add User"}
        icon={"pe-7s-users icon-gradient bg-happy-itmeo"}
        createBtnHanlde={() => setShowUserModal(true)}
        importBtnCSVHandle={
          <form encType="multipart/form-data">
            <Button
              type="default"
              htmlType="button"
              className="button-action"
              onClick={handleClick}
              style={{ marginLeft: "13px", border: "none" }}
            >
              <UploadOutlined /> Bulk Upload
            </Button>
            <input
              ref={hiddenFileInput}
              style={{ display: "none" }}
              id="import"
              type="file"
              name="import"
              className="import"
              accept=".csv"
              onChange={(e) => {
                e.preventDefault();
                let file =
                  e.target && e.target.files ? e.target.files[0] : null;
                usersFromCSV(file as File, setImportedUsers);
                setImportFile(file);
                setShowImportModal(true);
                e.target.value = "";
              }}
            />
          </form>
        }
        searchInput={
          <SearchBox
            filtered={
              usersData.length !== users.length
                ? () => setUsersData(users)
                : null
            }
            placeholder="Search by Name"
            onSearch={handleSearch}
          />
        }
      />
      <div className="page-container">
        <Table
          dataSource={usersData}
          rowKey={"id"}
          columns={columns}
          pagination={{ position: ["bottomRight"] }}
          size="middle"
          scroll={{ x: true }}
          tableLayout={"fixed"}
        />
      </div>

      <Modal
        title="Remove user"
        open={showDeleteModal}
        okText="Remove User"
        cancelText="Cancel"
        onOk={removeUser}
        onCancel={() => {
          setShowDeleteModal(false);
          setSelectedUser(null);
        }}
      >
        Any associated custom email will be permanently deleted after 90days. To
        delete permanently immediately, please create a support ticket via your
        admin center or contact support@javat365.com.
      </Modal>

      <Modal
        title={selectedUser ? "Edit user" : "Create new user"}
        open={showUserModal}
        okText="Save"
        cancelText="Cancel"
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              form.resetFields();
              if (selectedUser) {
                console.log(selectedUser);
                createUsersService
                  .patch(selectedUser._id, values)
                  .then((res: any) => {
                    dispatch(FetchUsers());
                  })
                  .catch((err: any) => console.log(err));
              } else {
                createOrUpdateUser(values, false);
              }
              setSelectedUser(null);
              setShowUserModal(false);
            })
            .catch((info) => console.log(info));
        }}
        okButtonProps={{ htmlType: "submit" }}
        onCancel={() => {
          setShowUserModal(false);
          setSelectedUser(null);
          form.resetFields();
        }}
      >
        <Form
          form={form}
          initialValues={initialValues}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Enter a valid email address!",
              },
            ]}
          >
            <Input
              type="email"
              disabled={selectedUser?.superAdmin || selectedUser?.objectId}
            />
          </Form.Item>
          {configurations?.domain && selectedUser?.username && (
            <Form.Item
              label="Tenant Email"
              rules={[{ required: true, message: "Required" }]}
            >
              <Input
                value={`${selectedUser?.username}@${configurations?.domain}`}
                disabled
              />
            </Form.Item>
          )}
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input
              disabled={selectedUser?.superAdmin || selectedUser?.objectId}
            />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[{ required: true, message: "Required" }]}
          >
            <Input
              disabled={selectedUser?.superAdmin || selectedUser?.objectId}
            />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              {
                min: 10,
                message: "Phone Number must be minimum 10 characters.",
              },
            ]}
          >
            <Input
              type="number"
              disabled={selectedUser?.superAdmin || selectedUser?.objectId}
            />
          </Form.Item>
          <Form.Item
            extra="Will be used as email if custom domain is activated."
            label="Username"
            name="username"
            rules={
              selectedUser?.external
                ? []
                : selectedUser?.objectId
                ? []
                : [
                    {
                      min: 3,
                      message: "Username must be minimum 3 characters.",
                    },
                    { required: true, message: "Required" },
                  ]
            }
          >
            <Input
              disabled={selectedUser?.superAdmin || selectedUser?.username}
            />
          </Form.Item>
          <Form.Item
            name="tags"
            label="Tags"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              style={{ width: "100%" }}
              placeholder="Select Tag"
              filterOption={(input: any, option: any) =>
                option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              allowClear
              mode="multiple"
              showSearch
              options={tags.map((u) => {
                return {
                  label: u.title,
                  value: u._id,
                };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[{ required: false, message: "Required" }]}
          >
            <Radio.Group disabled={selectedUser?.role === "super-admin"}>
              <Radio value="owner">User</Radio>
              <Radio value="admin">Admin</Radio>
              <Radio disabled={!isSuperAdmin} value="super-admin">
                Super Admin
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Import Users"
        open={showImportModal}
        onOk={async (e) => {
          dispatch(loadingAction(true));
          importUsersService
            .create(newUsers)
            .then((res: any) => {
              if (res.length) {
                error(
                  res,
                  "Please re-add users with unique username or contact support"
                );
                dispatch(loadingAction(false));
              } else {
                dispatch(FetchUsers());
                message.success("Users successfully imported!");
              }

              setShowImportModal(false);
            })
            .catch((err: any) => console.log(err));
          // error()
        }}
        onCancel={() => {
          setImportFile(null);
          setShowImportModal(false);
        }}
      >
        Are you sure you want import users?
      </Modal>
    </div>
  );
};

export default ManageUsers;

export const error = (data: any[], notifyMessage: string) => {
  message.error({
    content: (
      <div>
        {data.map((content) => (
          <div>
            <p>{content.key}</p>
            <ul>
              {content.values.map((item: string) => (
                <li>{item}</li>
              ))}
            </ul>
          </div>
        ))}
        <svg
          style={{ cursor: "pointer" }}
          viewBox="64 64 896 896"
          focusable="false"
          onClick={() => message.destroy()}
          data-icon="close-circle"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
        </svg>

        <p>{notifyMessage}</p>
      </div>
    ),
    duration: 0,
    className: "test",
  });
};
