import {
  ActionsEnum,
  ActionsType,
  IBin,
  IDocument,
  IEventLogs,
  IFeedItem,
  IGroupCreateRequests,
  IGroupMember,
  IGroupRequest,
  IMeeting,
  IMember,
  IMembers,
  INotification,
  ISiteGroup,
  IStats,
  ISubscriptionStats,
  IUserModel,
  IUsers} from "../types";
import { IContentCache } from "../types/index";
import api from "../utils/APIProvider";

export function loadingAction(loading: boolean): ActionsType {
  return {
    payload: loading,
    type: ActionsEnum.LOADING,
  };
}

export function loadingUsersAction(userLoading: boolean): ActionsType {
  return {
    payload: userLoading,
    type: ActionsEnum.USER_LOADING,
  };
}

export function authSuccessAction(token: string, user: IUserModel): ActionsType {
  return {
    payload: { token, user },
    type: ActionsEnum.AUTH_SUCCESS,
  };
}

export function userLogoutAction(): ActionsType {
  localStorage.removeItem("token");
  localStorage.removeItem("tenantId");
  localStorage.removeItem("primaryColor");

  api.removeToken();
  // authService.logout();
  return {
    type: ActionsEnum.LOGOUT,
  };
}

export function fetchUsersAction(users: IUsers): ActionsType {
  return {
    type: ActionsEnum.FETCH_USERS,
    payload: users,
  };
}

export function fetchAdminsAction(users: IUsers): ActionsType {
  return {
    type: ActionsEnum.FETCH_ADMINS,
    payload: users,
  };
}

export function fetchTenantUsersAction(users: IUsers): ActionsType {
  return {
    type: ActionsEnum.FETCH_TENANT_USERS,
    payload: users,
  };
}

export function fetchMembersAction(members: IMembers): ActionsType {
  return {
    type: ActionsEnum.FETCH_MEMBERS,
    payload: members,
  };
}
export function fetchMemberAction(member: IMember): ActionsType {
  return {
    type: ActionsEnum.FETCH_MEMBER,
    payload: member,
  };
}
export function fetchCurrentUserAction(user: IUserModel): ActionsType {
  return {
    type: ActionsEnum.FETCH_CURRENT_USER,
    payload: user,
  };
}

export function fetchStatsAction(stats: IStats): ActionsType {
  return {
    type: ActionsEnum.FETCH_STATS,
    payload: stats,
  };
}

export function fetchEventLogsAction(events: IEventLogs[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_EVENT_LOGS,
    payload: events,
  };
}

export function fetchBinAction(bin: IBin[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_BIN,
    payload: bin,
  };
}

export function fetchMeetingsAction(meeting: IMeeting[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_MEETINGS,
    payload: meeting,
  };
}

export function fetchMeetingAction(meeting: IMeeting): ActionsType {
  return {
    type: ActionsEnum.FETCH_MEETING,
    payload: meeting,
  };
}

export function fetchNotificationsAction(notifications: INotification[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_NOTIFICATIONS,
    payload: notifications,
  };
}

export function fetchSiteGroupsAction(groups: ISiteGroup[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_SITEGROUPS,
    payload: groups,
  };
}

export function fetchSiteGroupAction(group: ISiteGroup): ActionsType {
  return {
    type: ActionsEnum.FETCH_SITEGROUP,
    payload: group,
  };
}

export function fetchGroupRequestsAction(requests: IGroupRequest[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_GROUPREQUESTS,
    payload: requests,
  };
}

export function fetchGroupMemberRequestsAction(requests: IUserModel[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_NOTACTIVEUSERS,
    payload: requests,
  };
}

export function fetchCreateGroupRequestsAction(requests: IGroupCreateRequests[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_GROUP_CREATE_REQUESTS,
    payload: requests,
  };
}

export function fetchGroupDocuments(documents: IDocument[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_DOCUMENTS,
    payload: documents,
  };
}

export function fetchGroupDraftDocuments(documents: IDocument[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_DRAFT_DOCUMENTS,
    payload: documents,
  };
}

export function fetchFeedItemAction(feedItem: IFeedItem | undefined): ActionsType {
  return {
    type: ActionsEnum.FETCH_FEED_ITEM,
    payload: feedItem,
  };
}
export function fetchSharedFeedItemsAction(feedItems: IFeedItem[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_SHARED_FEED_ITEMS,
    payload: feedItems,
  };
}
export function fetchFeedItemsAction(feedItems: IFeedItem[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_FEED_ITEMS,
    payload: feedItems,
  };
}

export function fetchTenantAction(tenant: any): ActionsType {
  return {
    type: ActionsEnum.FETCH_TENANT,
    payload: tenant,
  };
}

export function fetchConfigAction(configurations: any): ActionsType {
  return {
    type: ActionsEnum.FETCH_CONFIG,
    payload: configurations,
  };
}

export function fetchTagsAction(tags: any[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_TAGS,
    payload: tags,
  };
}

export function fetchContentCachesAction(contentCaches: IContentCache[]): ActionsType {
  return {
    type: ActionsEnum.FETCH_CONTENT_CACHES,
    payload: contentCaches,
  };
}

export function fetchUserCachesAction(users: any): ActionsType {
  return {
    type: ActionsEnum.FETCH_USER_CACHES,
    payload: users,
  };
}

export function fetchUserGroupsAction(groups: any): ActionsType {
  return {
    type: ActionsEnum.FETCH_USER_GROUPS,
    payload: groups,
  };
}

export function fetchContentCacheAction(contentCache: IContentCache | null): ActionsType {
  return {
    type: ActionsEnum.FETCH_CONTENT_CACHE,
    payload: contentCache,
  };
}
