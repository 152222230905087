import axios, { AxiosInstance, AxiosResponse } from "axios";

import AuthService from "../services/AuthService";
import { userLogoutAction } from "../store/Actions";
import { store } from "../store/ConfigStore";

let http: AxiosInstance = axios.create({
  baseURL: `https://${
    localStorage.getItem("tenantId")?.toLowerCase()
  }.api.javat365.com`,
  // baseURL: "http://localhost:3003",
});

class APIProvider {
  constructor() {
    http.defaults.headers.common["Content-Type"] =
      "application/json;charset=UTF-8";
    http.defaults.headers.common["request-source"] = "client";

    const token = localStorage.getItem("token");
    const tenantId = localStorage.getItem("tenantId");

    if (tenantId) {
      http.defaults.baseURL = `https://${tenantId.toLowerCase()}.api.javat365.com`;
      // http.defaults.baseURL = "http://localhost:3003";
    }
    if (token) {
      http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }

    if (tenantId) {
      http.defaults.headers.common["TenantID"] = `Bearer ${tenantId}`;
    }

    const authInterceptor = (response: AxiosResponse) => {
      const token = localStorage.getItem("token");
      if (token) {
        response.headers.Authorization = `Bearer ${token}`;
      } else {
        console.log("NO TOKEN FOUND");
      }
      return response;
    };

    axios.interceptors.response.use(authInterceptor, function (error) {
      return Promise.reject(error);
    });

    http.interceptors.response.use(authInterceptor, async function (error) {
      if (
        (error.response.config.url === "users/me" ||
          error.response.config.url === "api/subscription-status") &&
        error.response.status === 403
      ) {
        store.dispatch(userLogoutAction());
      }
      if (
        !error.response.config.url.includes("login") &&
        error.response.status === 401
      ) {
        store.dispatch(userLogoutAction());
      }
      if (
        error.response.data.name === "JsonWebTokenError" &&
        error.response.status === 500
      ) {
        store.dispatch(userLogoutAction());
      }
      return Promise.reject(error);
    });
  }

  setBaseURL(tenantId: string) {
    localStorage.setItem("tenantId", tenantId);
    http.defaults.baseURL = `https://${tenantId.toLowerCase()}.api.javat365.com`;
    // http.defaults.baseURL = "http://localhost:3003";
  }

  saveToken(token: string) {
    localStorage.setItem("token", token);
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  }

  saveUser(userId: string) {
    localStorage.setItem("userId", userId);
  }

  removeToken() {
    http.defaults.headers.common["Authorization"] = "";
  }

  tokenLogin(data: any) {
    return http.post("auth/token/login", data);
  }

  login(data: any) {
    return http.post("login", data);
  }

  logout() {
    localStorage.removeItem("token");
  }

  // REST Methods
  async photo(url: string) {
    const authService = new AuthService();
    const token = (await authService.getMSALToken()) || "";
    const headers = new Headers({ Authorization: `Bearer ${token}` });
    const options = {
      headers,
    };
    try {
      const response = await fetch(`${url}`, options);
      return response;
    } catch (err: any) {
      console.log("Geet Users in Tenant error: ", err.message);
      return err;
    }
  }

  fileUpload(path: string, file: any) {
    let data = new FormData();
    data.append("file", file);
    return http.post(`${path}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  contentImageUpload(path: string, file: any) {
    let data = new FormData();
    data.append("file", file);
    return http.post(`${path}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }

  get(path: string, query?: any) {
    return http.get(path, { params: query });
  }

  post(path: string, data: any, query?: any) {
    return http.post(path, data, {
      params: query,
    });
  }

  update(path: string, data: any, query?: any) {
    return http.put(`${path}`, data, {
      params: query,
    });
  }

  patch(path: string, data: any, query?: any) {
    return http.patch(`${path}`, data, {
      params: query,
    });
  }

  delete(path: string, data?: any, query?: any) {
    return http.delete(`${path}`, data);
  }

  signUrl(url: string) {
    return http.post("api/signurl", { url });
  }
}

export default new APIProvider();
