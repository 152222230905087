import {
  InfoCircleOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import {
  AutoComplete,
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Grid,
  Layout as AntLayout,
  Menu,
  message,
  Modal,
  Row,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import DefaultFallback from "../components/DefaultFallback";
import { userLogoutAction } from "../store/Actions";
import {
  AutoCompleteUser,
  DeleteUserFromGroup,
  FetchUserGroups,
} from "../store/Effects";
import { AppStateType } from "../types";
import Loader from "./Loader";
import { Breadcrumb, Navigation } from "./Navigation";
const debounce = require("lodash.debounce");
const { Option } = AutoComplete;

const { Header, Footer, Sider, Content } = AntLayout;
const { useBreakpoint } = Grid;

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);
  const screens = useBreakpoint();

  const location = useLocation();

  const currentUser = useSelector(
    (state: AppStateType) => state.mainStore.user,
    shallowEqual
  );
  const userCaches: any = useSelector(
    (state: AppStateType) => state.mainStore.userCaches,
    shallowEqual
  );
  const userGroups: any = useSelector(
    (state: AppStateType) => state.mainStore.userGroups,
    shallowEqual
  );
  const loading = useSelector(
    (state: AppStateType) => state.mainStore.loading,
    shallowEqual
  );
  const userLoading = useSelector(
    (state: AppStateType) => state.mainStore.userLoading,
    shallowEqual
  );
  const isAdmin = useSelector(
    (state: AppStateType) => state.mainStore.isAdmin,
    shallowEqual
  );
  const isSuperAdmin = useSelector(
    (state: AppStateType) => state.mainStore.isSuperAdmin,
    shallowEqual
  );
  const isOwner = useSelector(
    (state: AppStateType) => state.mainStore.isOwner,
    shallowEqual
  );
  const [openModal, setOpenModal] = useState(false);
  const [selectedUser, setUser] = useState<any>(null);

  const dispatch = useDispatch();

  const triggerClickHandle = () => {
    setCollapsed(!collapsed);
    setDrawerVisible(!drawerVisible);
  };

  const logoutClickHandle = () => {
    dispatch(userLogoutAction());
  };

  const [, contextHolder] = message.useMessage();

  const handleSearch = (value: string) => {
    dispatch(AutoCompleteUser(value));
  };

  const handleInputChange = (value: any) => {
    setValue(value);
    dispatch(AutoCompleteUser(value));
  };

  const userColumns = [
    {
      title: "Groups",
      dataIndex: "displayName",
      key: "group",
      align: "left" as const,
      render: (group: any, record: any) => (
        <div>
          <span>{record?.group?.title}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "action",
      align: "right" as const,
      render: (action: any, record: any) => {
        return (
          <div>
            <Button
              disabled={
                ["admin", "super-admin"].includes(currentUser?.role || "") &&
                !selectedUser.isAdmin
                  ? false
                  : userGroups.find(
                      (el: any) =>
                        el.group._id === record.group._id &&
                        el.user._id === currentUser?._id
                    )?.role === "owner" && !selectedUser.isAdmin
                  ? false
                  : true
              }
              type="default"
              htmlType="button"
              danger
              onClick={(e) => {
                e.preventDefault();
                Modal.confirm({
                  title: "Remove user",
                  content: `Are you sure you want to remove this user from this group?`,
                  okText: "Remove",
                  okButtonProps: {
                    htmlType: "button",
                    danger: true,
                  },
                  onOk: () => {
                    dispatch(DeleteUserFromGroup(record._id, selectedUser.key, currentUser?._id));
                  },
                });
              }}
            >
              Remove
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setValue("");
  }, [location]);

  const [value, setValue] = useState("");

  return (
    <>
      {loading || userLoading ? <Loader userLoading={userLoading} /> : null}
      <AntLayout
        style={{ minHeight: "100vh" }}
        hasSider={true}
        className={"layout-container"}
      >
        {/* SLIDER */}
        {screens.xl && (
          <Sider
            width="260"
            collapsible={true}
            collapsed={collapsed}
            // onCollapse={() => setCollapsed(!collapsed)}
            trigger={null}
            breakpoint="xl"
            collapsedWidth={!screens.lg ? 0 : 80}
            className={"slider"}
          >
            <Navigation collapsed={collapsed} />
          </Sider>
        )}

        <AntLayout>
          {/* HEADER */}
          <Header className={"header"}>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: triggerClickHandle,
              }
            )}

            {screens.sm && (
              <AutoComplete
                allowClear={true}
                placeholder="Search users"
                style={{ width: "50%" }}
                onSearch={debounce(handleSearch, 300)}
                onSelect={(value: any, option: any) => {
                  dispatch(
                    FetchUserGroups(option.key, currentUser?._id, setOpenModal)
                  );
                  setUser(option);
                }}
                value={value}
                onChange={(value) => {
                  handleInputChange(value);
                }}
              >
                {userCaches.length &&
                  userCaches.map((item: any = {}) => (
                    <Option
                      isAdmin={
                        item.role === "admin" || item.role === "super-admin"
                      }
                      key={item._id}
                      value={`${item.firstName} ${item.lastName}`}
                    >
                      <div className="flex d-flex justify-content-between">
                        <span>
                          <Tag
                            className="text-center"
                            color={
                              item.role === "super-admin"
                                ? "cyan"
                                : item.role === "admin"
                                ? "orange"
                                : item.role === "external"
                                ? "purple"
                                : "blue"
                            }
                          >
                            {item.role === "super-admin"
                              ? "Super Admin"
                              : item.role === "admin"
                              ? "Admin"
                              : item.role === "external"
                              ? "External User"
                              : "Internal User"}
                          </Tag>
                          {item.firstName} {item.lastName}
                        </span>

                        <span>
                          {item.email}
                          <Tag
                            className="ml-2 text-center"
                            color={!item.deleted ? "green" : "red"}
                          >
                            {item.deleted ? "Inactive" : "Active"}
                          </Tag>
                        </span>
                      </div>
                    </Option>
                  ))}
              </AutoComplete>
            )}

            <Modal
              title="Delete user from group"
              width="500px"
              open={openModal}
              onOk={() => {
                setOpenModal(false);
              }}
              footer={null}
              onCancel={() => {
                setOpenModal(false);
              }}
            >
              <br />
              <Table
                dataSource={userGroups?.filter(
                  (group: any) => selectedUser?.key === group?.user?._id
                )}
                columns={userColumns}
                pagination={false}
                size="small"
                scroll={{ y: 600 }}
                tableLayout={"fixed"}
              />{" "}
            </Modal>
            <Row>
              <Dropdown
                overlay={
                  <Menu>
                    {currentUser?.objectId ? (
                      <Menu.Item key="0">
                        <a
                          href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Microsoft+Account.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Microsoft Internal User
                        </a>
                      </Menu.Item>
                    ) : isSuperAdmin ? (
                      <>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Super+Admin.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Super Admin
                          </a>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Admin.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Admin
                          </a>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Group+Owner.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Internal User
                          </a>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Microsoft+Account.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Microsoft Internal User
                          </a>
                        </Menu.Item>
                      </>
                    ) : isAdmin ? (
                      <>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Admin.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Admin
                          </a>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Group+Owner.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Internal User
                          </a>
                        </Menu.Item>
                        <Menu.Item key="0">
                          <a
                            href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Microsoft+Account.pdf"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Microsoft Internal User
                          </a>
                        </Menu.Item>
                      </>
                    ) : isOwner ? (
                      <Menu.Item key="0">
                        <a
                          href="https://s3.us-east-2.amazonaws.com/com.javat365.content/User+Guide+-+Group+Owner.pdf"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          Internal User
                        </a>
                      </Menu.Item>
                    ) : null}
                  </Menu>
                }
                trigger={["click"]}
              >
                <div className={"user-dropdown helpMenu"}>
                  <div className={"username"}>Help</div>
                  <Avatar
                    size="default"
                    icon={<InfoCircleOutlined />}
                    // src={store.user.picture}
                  />
                </div>
              </Dropdown>

              <Dropdown overlay={menu(logoutClickHandle)} trigger={["click"]}>
                <div className={"user-dropdown"}>
                  <div className={"username"}>
                    {currentUser?.displayName
                      ? `${currentUser?.displayName}`
                      : `${currentUser?.firstName} ${currentUser?.lastName}`}
                  </div>
                  <Avatar
                    size="default"
                    icon={<UserOutlined />}
                    // src={store.user.picture}
                  />
                </div>
              </Dropdown>
            </Row>
          </Header>

          {/* CONTENT BODY */}

          {location.pathname.startsWith("/join-meeting/") ? null : (
            <Breadcrumb locationPath={location.pathname} />
          )}

          {contextHolder}

          <React.Suspense fallback={<DefaultFallback />}>
            <Content className={"content"}>{props.children}</Content>
          </React.Suspense>

          {/* FOOTER */}
          <Footer style={{ textAlign: "center" }}>
            Copyright © JAVAT 365 - {new Date().getFullYear()}{" "}
            {process.env.REACT_APP_VERSION}{" "}
          </Footer>
        </AntLayout>

        {/* DRAWER */}
        {!screens.xl && (
          <Drawer
            placement="left"
            closable={true}
            onClose={() => setDrawerVisible(false)}
            visible={drawerVisible}
          >
            <Navigation collapsed={false} drawerClose={setDrawerVisible} />
          </Drawer>
        )}
      </AntLayout>
    </>
  );
};

export default Layout;

const menu = (logoutClickHandle: () => void) => (
  <Menu>
    <Menu.Item key="0">
      <a
        href="https://forms.office.com/r/Eun0syWvmtf"
        rel="noopener noreferrer"
        target="_blank"
      >
        Feedback
      </a>
    </Menu.Item>
    <Menu.Item key="0" onClick={logoutClickHandle}>
      Logout
    </Menu.Item>
  </Menu>
);
