import "./styles/index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";

import {registerLicense} from '@syncfusion/ej2-base'
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/ConfigStore";

registerLicense('Mgo+DSMBaFt/QHRqVVhjVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSH5Vd0FhXX1XeHNTTw==;Mgo+DSMBPh8sVXJ0S0J+XE9HflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31Td0VlWHtcdHZcTmhVWA==;ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjX35acnBXT2ldU00=;ODkxMjE5QDMyMzAyZTM0MmUzMFc5a3JYdmRCbThISVh0Zjg2N0wraGk1NlNqWDNodzJmaTRtbDFFZGtkTFU9;ODkxMjIwQDMyMzAyZTM0MmUzMFRhcUd1NVhzS29FU3FPYnZvVmZxSE5qM2ZCZERWU2x1UVRBTHRJNXNVSTg9;NRAiBiAaIQQuGjN/V0Z+WE9EaFxKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUViWXlfdHZRRGlVWUd/;ODkxMjIyQDMyMzAyZTM0MmUzMERhbUV5RjBpWVkxOUU5VzNkWmFPUU0ySkR4MCtwamg5ZXNiVVlCWmROWEk9;ODkxMjIzQDMyMzAyZTM0MmUzME16NnNlZE5pVWYvazd1cUFtNkllY01WbkNWdUVNSCtlZ3hwUUl5OE5ETkE9;Mgo+DSMBMAY9C3t2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRjX35acnBWRmBYVE0=;ODkxMjI1QDMyMzAyZTM0MmUzMEYxNFpkMTBWSjgybFlNN3ZIYlgxNUgxSFFOd3NjQjJ3cTlXbGlqTUI1UTA9;ODkxMjI2QDMyMzAyZTM0MmUzMG0wZUd6aG9aZGsvRkN6L2MyTDJLNlRwbnBBenVCNkVLUS94Vys4YmVPNE09;ODkxMjI3QDMyMzAyZTM0MmUzMERhbUV5RjBpWVkxOUU5VzNkWmFPUU0ySkR4MCtwamg5ZXNiVVlCWmROWEk9')

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

const container = document.getElementById("root") as HTMLElement;
createRoot(container).render(app);
serviceWorker.unregister();
