// @ts-nocheck
import feathers from "@feathersjs/client";
import rest from "@feathersjs/rest-client";
import { message, Modal, notification } from "antd";
import dayjs from "dayjs";
import { History } from "history";
import { ThunkAction } from "redux-thunk";

import { BASE_URL } from "../constants";
import { error } from "../pages/ManageUsers";
import AuthService from "../services/AuthService";
import { ActionsType, AppStateType, IFeedItem, IMeeting } from "../types";
import { getTypeOfFileFormat } from "../utils";
import api from "../utils/APIProvider";
import uploadFiles from "../utils/uploadFiles";
import {
  authSuccessAction,
  fetchAdminsAction,
  fetchBinAction,
  fetchConfigAction,
  fetchContentCacheAction,
  fetchContentCachesAction,
  fetchCreateGroupRequestsAction,
  fetchCurrentUserAction,
  fetchEventLogsAction,
  fetchFeedItemAction,
  fetchFeedItemsAction,
  fetchGroupDocuments,
  fetchGroupDraftDocuments,
  fetchGroupMemberRequestsAction,
  fetchGroupRequestsAction,
  fetchMeetingAction,
  fetchMeetingsAction,
  fetchMembersAction,
  fetchNotificationsAction,
  fetchSharedFeedItemsAction,
  fetchSiteGroupAction,
  fetchSiteGroupsAction,
  fetchStatsAction,
  fetchTagsAction,
  fetchTenantAction,
  fetchTenantUsersAction,
  fetchUserCachesAction,
  fetchUserGroupsAction,
  fetchUsersAction,
  loadingAction,
  loadingUsersAction,
  userLogoutAction,
} from "./Actions";

type Effect = ThunkAction<any, AppStateType, any, ActionsType>;

const authService = new AuthService();

const app = feathers();
// Connect to a different URL
const restClient = rest(BASE_URL);

// Configure an AJAX library (see below) with that client
app.configure(restClient.fetch(window.fetch.bind(window)));

export function MSALUserLogin(history: History, path: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const user = await authService.login();
      if (user) {
        const msalToken = (await authService.getMSALToken()) || "";
        if (msalToken) {
          dispatch(
            UserLogin(
              {
                strategy: "microsoft",
                credentials: { token: msalToken },
              },
              history,
              "/"
            )
          );
        } else {
          dispatch(loadingAction(false));
        }
      }
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function UserLogin(data: any, history: History, path: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      delete data.orgid;
      api
        .login(data)
        .then((res) => {
          console.log("login success", path, res.data);

          api.saveToken(res.data.token);
          api.saveUser(res.data.user._id);
          dispatch(authSuccessAction(res.data.token, res.data.user));
          setTimeout(function () {
            window.location.replace("/");
          }, 200);
        })
        .catch((err) => {
          if (
            err.message ===
            `Cannot read properties of undefined (reading 'config')`
          ) {
            message.error("Invalid Tenant ID");
          } else {
            message.error(
              err?.response?.data?.message
                ? err.response.data.message
                : err.message
            );
          }
          dispatch(loadingAction(false));

          return new Error(err);
        });
    } catch (err) {
      message.error(
        err?.response?.data?.message
          ? err?.response?.data?.message
          : err.message || "Invalid Tenant ID"
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ActivateTenant(data: any, history: History): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .post(`microsoft`, { intent: "activate", data })
        .then((res) => {
          dispatch(FetchConfig());
          history.push("/settings?syncusers=true");
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );

          return new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchMeeting(id, org): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const res = await api.get(
        `https://${org}.api.javat365.com/meetings/${id}?$links=true&$populate=host&$populate=invitedUsers`
      );
      dispatch(fetchMeetingAction(res.data));
    } catch (err) {
      console.error(err);
      //error.response.data.message
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function FetchCurrentUser(): Effect {
  return async function (dispatch, getState) {
    try {
      const userId = getState().mainStore.userId;
      dispatch(loadingAction(true));
      const res = await api.get(`users/${userId}`);
      dispatch(fetchCurrentUserAction(res.data));
    } catch (err) {
      console.error(err);
      //error.response.data.message
      // message.error(
      //   err?.response?.data?.message ? err.response.data.message : err.message
      // );
      if (err.response.status === 404) {
        dispatch(userLogoutAction());
      }
      dispatch(loadingAction(false));
    }
  };
}

export function FetchTags(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const res = await api.get(`user-tags?$sort[createdAt]=-1`);
      dispatch(fetchTagsAction(res.data));
    } catch (err) {
      console.error(err);
      //error.response.data.message
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function SSOLoginEffect(
  data: { email: string; token: string },
  history: History
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const res = await api.post(`/auth/sso-login`, data);
      api.saveToken(res.data.token);
      //history.replace(path);
      setTimeout(function () {
        window.location.replace("/");
      }, 200);

      dispatch(authSuccessAction(res.data.token, res.data));
    } catch (err) {
      console.error(err);
      //error.response.data.message
      // history.push("/login");
      // if (err.message === `Cannot read properties of undefined (reading 'config')`) {
      //   message.error('Invalid Tenant ID');
      // } else {
      //   message.error(err?.response?.data?.message ? err.response.data.message : err.message);
      // }
      // dispatch(loadingAction(false));
    }
  };
}

export function ResetPassword(email: string, history: History): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.post(`reset-password`, { email });
      message.success({
        content:
          "Operation successfull you should receive an email, click the link from the mail to continue",
      });
      history.push("/login");
      dispatch(loadingAction(false));
    } catch (err) {
      console.error(err);
      if (
        err.message === `Cannot read properties of undefined (reading 'config')`
      ) {
        message.error("Invalid Tenant ID");
      } else {
        message.error(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      }
      dispatch(loadingAction(false));
    }
  };
}

export function SetPassword(
  data: { password: string; email?: string; token: string },
  history: History
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.post(`set-password`, data);
      message.success({
        content: "Password Set Succesfully, Login Now",
      });
      history.push("/login");
      dispatch(loadingAction(false));
    } catch (err) {
      console.error(err);
      //error.response.data.message
      history.push("/login");
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function UpdateGroupPhoto(groupId: string, file: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const imgUrls = await uploadFiles([file]);
      await api.patch(`groups/${groupId}`, {
        avatar: imgUrls[0],
      });
      dispatch(FetchSiteGroup(groupId));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function CreateAdmin(data: any): Effect {
  return async function (dispatch) {
    try {
      // console.log(data)
      dispatch(loadingAction(true));
      await api.post(`users/admin`, data).then((_) => {
        dispatch(FetchAdmins());
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function FetchUsers(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api
        .get(
          `users?$sort[createdAt]=-1&role=admin&r&role=owner&role=super-admin&deleted=false`
        )
        .then((res) => {
          dispatch(fetchUsersAction(res.data.data));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateUser(data: any, continueAdd?: string = false): Effect {
  return async function (dispatch) {
    try {
      console.log(data);
      dispatch(loadingAction(true));
      await api.post(`users?continue=${continueAdd}`, data).then((res) => {
        dispatch(FetchUsers());
      });
    } catch (err) {
      dispatch(loadingAction(false));
      console.log(err);
      if (
        err?.response?.data?.message.includes(
          "already exist as an external member"
        )
      ) {
        return Modal.confirm({
          title: "Update User",
          content: `User already exist as an external member. To promote as an internal user, please confirm.`,
          okText: "Confirm",
          okButtonProps: {
            htmlType: "button",
          },
          onCancel: () => {
            dispatch(loadingAction(false));
          },
          onOk: () => {
            dispatch(CreateUser(data, true));
          },
        });
      }
      if (err?.response?.data?.message.includes("User recently deleted")) {
        return Modal.confirm({
          title: "Update User",
          content: `User recently deleted. Please confirm to reinstate.`,
          okText: "Confirm",
          okButtonProps: {
            htmlType: "button",
          },
          onCancel: () => {
            dispatch(loadingAction(false));
          },
          onOk: () => {
            dispatch(CreateUser(data, true));
          },
        });
      }
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function UpdateUser(id: string, data: any, isAdmin = false): Effect {
  return async function (dispatch) {
    try {
      if (!data.onboarded) dispatch(loadingAction(true));
      await api.patch(`users/${id}`, data).then((res) => {
        if (data.onboarded) return;
        if (isAdmin) {
          dispatch(FetchAdmins());
        } else {
          dispatch(FetchUsers());
        }
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateTag(id: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.patch(`user-tags/${id}`, data).then((res) => {
        dispatch(FetchTags());
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateTag(data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.post(`user-tags`, data).then((res) => {
        dispatch(FetchTags());
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateAdminUser(data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.post(`users/admin`, data).then((res) => {
        dispatch(FetchAdmins());
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateAdminUser(id: string, data: any): Effect {
  return async function (dispatch) {
    try {
      // console.log(memberId, data )
      dispatch(loadingAction(true));
      await api.update(`users/${id}/admin`, data).then((res) => {
        dispatch(FetchAdmins());
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchAdmins(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api
        .get(`users?$sort[createdAt]=-1&role=super-admin`)
        .then((res) => {
          dispatch(fetchAdminsAction(res.data.data));
        });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function FetchTenantUsers(setSelectedMembers: any): Effect {
  return async function (dispatch) {
    dispatch(loadingUsersAction(true));
    try {
      dispatch(loadingAction(true));
      await api.get(`microsoft`).then((res) => {
        const selected = res.data.filter((item) => item.selected);
        setSelectedMembers(selected);
        dispatch(fetchTenantUsersAction(res.data));
      });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function AddTenantUsers(data): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api.post(`microsoft`, { intent: "add-users", data }).then((res) => {
        message.success({
          content: "Your users have been synchronized successfully.",
          duration: 7,
        });
        dispatch(loadingAction(false));
      });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function DeleteUser(userId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`users/${userId}`);

      dispatch(FetchUsers());
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteTag(id: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const { data } = await api.get(
        `/users?tags[$exists]=true&tags[$in]=${id}`
      );
      const users = data.data;
      for (let i = 0; i < users.length; i++) {
        const user = users[i];
        await api.patch(`users/${user._id}`, { $pull: { tags: id } });
      }
      await api.delete(`user-tags/${id}`).then((res) => {
        dispatch(FetchTags());
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export const rejectGroupMember = (userId: string, reason: string) => {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const { data: groups } = await api.get(`group-members?user=${userId}`);
      const groupsUserJoined = groups.data;
      await api.delete(`group-members-requests/${userId}`, {
        params: { reason, sendEmail: true },
      });
      for (let i = 0; i < groupsUserJoined.length; i++) {
        await api.delete(`group-members/${groupsUserJoined[i]._id}`);
      }
      dispatch(FetchGroupMembersRequests());
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
};
export const approveGroupMember = (userId: string) => {
  return async function (dispatch) {
    dispatch(loadingAction(true));
    api
      .patch(`group-members-requests/${userId}`, {
        active: true,
      })
      .then((res) => {
        dispatch(FetchGroupMembersRequests());
        if (res.data?.code == 400) {
          throw new Error(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
        message.error(
          err?.response?.data?.message ? err.response.data.message : err.message
        );

        dispatch(loadingAction(false));
      });
  };
};

export function ImportMembers(
  groupId: string,
  users: any[],
  userId: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      const { data } = await api.post("group-members-requests", {
        users,
        requestedBy: userId,
      });
      let messages = [];
      if (data.messages.length) {
        messages = data.messages;
      }

      const newUser = data.newUser;
      const duplicate = data.duplicated;

      if (newUser && newUser.length) {
        const groupMembers = newUser.map((item) => ({
          user: item._id,
          group: groupId,
        }));
        await api.post("group-members", groupMembers);
        console.log("1");
        messages.push({
          key: "The below External Users added to the Group are new within the Organization. Request has been made for approval.",
          values: newUser.map(
            (item) => item.firstName + " " + item.lastName + "; "
          ),
        });
      }
      if (duplicate && duplicate.length) {
        const { data: members } = await api.get(
          `group-members?group=${groupId}&$populate[0]=user&$populate[1]=user.tags`
        );

        const alreadyExistUser = members.data.filter((member) =>
          duplicate.find((user) => member.user?._id === user?._id)
        ); // already exist in group as member
        const newUser = duplicate.filter((item1) => {
          return !members.data.some((item2) => item1?._id === item2.user?._id);
        }); // not exist in group but already exist as user

        if (newUser && newUser.length) {
          const groupMembers = newUser.map((item) => ({
            user: item._id,
            group: groupId,
          }));
          await api.post("group-members", groupMembers);

          const newUserActives = newUser.filter(
            (user) => !user.hasOwnProperty("active") || user?.active
          ); // new user in group but active ones
          const newUserNotActives = newUser.filter(
            (user) => user.hasOwnProperty("active") && !user?.active
          ); // new user in group but not active ones

          if (newUserActives && newUserActives.length) {
            console.log(newUserActives);
            // console.log('some message');
            messages.push({
              key: "The below External Users are added to the Group.",
              values: newUserActives.map(
                (item) => item.firstName + " " + item.lastName + "; "
              ),
            });
          }
          if (newUserNotActives && newUserNotActives.length) {
            console.log("3");
            messages.push({
              key: "The below External Users added to the Group are new within the Organization and request has already been made for approval by another group.",
              values: newUserNotActives.map(
                (item) => item.firstName + " " + item.lastName + "; "
              ),
            });
          }
        }
        if (alreadyExistUser && alreadyExistUser.length) {
          const newUserActives = alreadyExistUser.filter(
            (el) => !el?.user?.hasOwnProperty("active") || el?.user?.active
          ); // already in group and active
          const newUserNotActives = alreadyExistUser.filter(
            (el) => el?.user?.hasOwnProperty("active") && !el?.user?.active
          ); // already in group and not active
          console.log(newUserNotActives, newUserActives);
          if (newUserActives && newUserActives.length) {
            console.log("4");
            messages.push({
              key: "The below user(s) already exist in the group:",
              values: newUserActives.map(
                (item) => item.user.firstName + " " + item.user.lastName + "; "
              ),
            });
          }
          if (newUserNotActives && newUserNotActives.length) {
            console.log("5");
            messages.push({
              key: "The below External Users added to the Group are new within the Organization and request has already been made for approval by another group.",
              values: newUserNotActives.map(
                (item) => item.user.firstName + " " + item.user.lastName + "; "
              ),
            });
          }
        }
      }

      if (messages.length) {
        error(messages, "");
      }
      dispatch(FetchMembers(groupId));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ImportUsers(users: any[], error: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      await api.post("/users", users);

      dispatch(FetchUsers());
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function addUserByTags(groupId: string, tags: string[]): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const { data: group } = await api.get(`groups/${groupId}`);
      const { data: members } = await api.get(
        `group-members?group=${groupId}&$populate[0]=user&$populate[1]=user.tags`
      );

      if (!tags.length && !group?.tags.length) {
        dispatch(loadingAction(false));
        return;
      }

      let sameTags = group?.tags?.filter((obj1) =>
        tags.some((obj2) => obj1 === obj2)
      );

      const allTags = group?.tags
        ? [...new Set([...group.tags, ...tags])]
        : [...new Set([...tags])]; // getting only unique tags
      const { data: users } = await api.get(`users`, {
        tags: { $in: allTags },
      });

      if (!users.data.length) {
        message.error("There is no member added in this tag's");
        dispatch(loadingAction(false));
        return;
      }

      const tagsWhichWillBeAdded = [...new Set([...sameTags, ...tags])];
      const tagsWhichWillBeRemoved = group?.tags?.filter(
        (x) => !tagsWhichWillBeAdded.includes(x)
      );

      const userWhichWillAdd = users.data.filter((user) => {
        const matchedTags = user?.tags.filter((el) =>
          tagsWhichWillBeAdded?.includes(el)
        );
        return matchedTags.length > 0;
      });
      const usersWhichWIllRemove = users.data.filter((user) => {
        const matchedTags = user?.tags.filter((el) =>
          tagsWhichWillBeRemoved?.includes(el)
        );
        return matchedTags.length > 0;
      });

      const uniqueObjects = userWhichWillAdd?.filter(
        (el) => !members.data.some((member) => member.user._id === el._id)
      );

      if (usersWhichWIllRemove && usersWhichWIllRemove.length) {
        const ids = usersWhichWIllRemove.map((user) => user._id);
        const membersWillBeDeleted = members.data
          .filter(
            (member) =>
              ids.includes(member.user._id) &&
              member.group === groupId &&
              member.role !== "owner" &&
              !["admin", "super-admin"].includes(member?.user?.role)
          )
          .map((member) => member._id);
        for (let i = 0; i < membersWillBeDeleted.length; i++) {
          const id = membersWillBeDeleted[i];
          await api.delete(`group-members/${id}`);
        }
      }
      if (uniqueObjects.length) {
        const userAddedToGroup = uniqueObjects.map((user) => ({
          group: groupId,
          user: user._id,
        }));
        for (let i = 0; i < userAddedToGroup.length; i++) {
          const user = userAddedToGroup[i];
          await api.post("group-members", {
            group: groupId,
            user: user.user,
          });
        }
      }
      // const owners = members.data?.filter(member => member.role === 'owner')?.map(member => member.user._id)
      await api.patch(`groups/${groupId}`, { tags });
      dispatch(FetchMembers(groupId));
      dispatch(FetchSiteGroup(groupId));
    } catch (err) {
      console.log(err);
      message.error(
        err?.response?.data?.message ? "User already exist." : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function CreateMember(
  groupId: string,
  data: {
    _id?: string | undefined;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    tags?: string[] | undefined;
  },
  userId: string,
  action: "create" | "update"
): Effect {
  return async function (dispatch) {
    try {
      const { data: userExistWithGiveEmail } = await api.get(`users`, {
        email: data.email,
      });
      if (
        userExistWithGiveEmail.data.length &&
        userExistWithGiveEmail.data[0]._id
      ) {
        const { data: member } = await api.get(
          `group-members?group=${groupId}&user=${userExistWithGiveEmail.data[0]._id}`
        );
        if (!member.data.length) {
          // no request will be send
          await api.post(`group-members`, {
            group: groupId,
            user: userExistWithGiveEmail.data[0]._id,
          });
        }
        if (
          (!userExistWithGiveEmail.data[0].hasOwnProperty("active") ||
            userExistWithGiveEmail.data[0]?.active) &&
          member.data.length
        ) {
          message.error(
            `The ${data.firstName} ${data.lastName} already exist in the group`
          );
        }
        if (
          userExistWithGiveEmail.data[0].hasOwnProperty("active") &&
          !userExistWithGiveEmail.data[0]?.active
        ) {
          message.error(
            // `The ${data.firstName} ${data.lastName} are already exist in the group`
            `${data.firstName} ${data.lastName}, added to the Group is new within the Organization. Request has already been made for approval.`
          );
        }
      } else {
        const { data: addedUser } = await api.post("group-members-requests", {
          users: [{ ...data }],
          requestedBy: userId,
        });
        console.log(addedUser);
        if (addedUser.messages?.length) {
          error(addedUser.messages, "");
        }
        if (addedUser.newUser?.length) {
          await api.post(`group-members`, {
            group: groupId,
            user: addedUser.newUser[0]._id,
          });
          message.success(
            `${data.firstName} ${data.lastName}, added to the Group is new within the Organization. Request has been made for approval.`
          );
        } else {
          message.error(
            `${data.firstName} ${data.lastName}, added to the Group is new within the Organization. Request has already been made for approval.`
          );
        }
      }
      dispatch(FetchMembers(groupId));
      dispatch(FetchSiteGroup(groupId));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? "User already exist." : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateMember(
  groupId: string,
  userId: string,
  data: any
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.patch(`users/${userId}`, data).then((res) => {
        dispatch(FetchMembers(groupId));
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}
export function FetchMembers(groupId: string, filter: any = {}): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      const query = new URLSearchParams();
      for (const [key, value] of Object.entries(filter)) {
        query.set(key, value);
      }

      await api
        .get(
          `group-members?group=${groupId}&$populate[0]=user&$populate[1]=user.tags&limit=100`
        )
        .then((res) => {
          let data = res.data.data.filter(
            (el) => !el?.user?.hasOwnProperty("active") || el?.user?.active
          );
          dispatch(fetchMembersAction(data.filter((el) => !el?.user?.deleted)));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchGroupMembersRequests(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      await api
        .get(`users?active=false&$populate[0]=requestedBy&$sort[updatedAt]=-1`)
        .then((res) => {
          dispatch(fetchGroupMemberRequestsAction(res.data.data));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchMember(groupId: string, memberId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api
        .get(
          `group-members?group=${groupId}&$populate[0]=user&$populate[1]=user.tags`
        )
        .then((res) => {
          dispatch(fetchMembersAction(res.data));
        });
      // const res = await api.get({ resource: "users", id: "me" });
      //console.log(res.value)
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateMembersStatus(groupId: string, data?: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api
        .post(`siteGroups/${groupId}/members/status`, data)
        .then((res) => {
          dispatch(FetchMembers(groupId));
        });
      // const res = await api.get({ resource: "users", id: "me" });
      //console.log(res.value)
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

// test

export function DeleteMember(groupId: string, memberId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`group-members/${memberId}`).then((res) => {
        dispatch(FetchMembers(groupId));
      });
      // const res = await api.get({ resource: "users", id: "me" });
      //console.log(res.value)
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteMembers(groupId: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      for (let i = 0; i < data.ids.length; i++) {
        const id = data.ids[i]._id;
        const userId = data.ids[i].userId;

        const { data: user } = await api.get(`group-members?user=${userId}`);
        if (user.data.length === 1 && user.data[0].user?.role === "external") {
          await api.delete(`group-members-requests/${userId}`, {
            params: {
              sendEmail: false,
            },
          });
        }
        await api.delete(`group-members/${id}`, {
          group: groupId,
        });
      }
      dispatch(FetchMembers(groupId));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchStats(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get("/stats")
        .then((res) => {
          dispatch(fetchStatsAction(res.data));
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function AddDomain(
  domain: string,
  setLoading: any,
  setDomainModal: any
): Effect {
  return async function (dispatch) {
    try {
      setLoading(true);
      await api.patch("/configuration", { domain });
      setLoading(false);
      setDomainModal(false);
      message.success({
        content:
          "Your domain have been verified successfully, all email addresses would be created in less than 24 hours. Existing users would be notified.",
        duration: 20,
      });
      dispatch(FetchConfig());
    } catch (err) {
      message.error({
        content: err?.response?.data?.message
          ? err.response.data.message
          : err.message,
        duration: 2,
      });
      setLoading(false);
    }
  };
}

export function DeleteDomain(): Effect {
  return async function (dispatch) {
    try {
      // console.log(memberId, data )
      dispatch(loadingAction(true));
      await api.patch(`configuration`, { domain: null }).then((res) => {
        window.location.reload();
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchEventLogs(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`event-logs?$sort[createdAt]=-1&$populate=group&$populate=user`, {
          page: 0,
          $limit: 10000,
        })
        .then((res) => {
          console.log(res, " evnt logs response");
          dispatch(fetchEventLogsAction(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchBin(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`bin?$populate=deletedBy&$sort[createdAt]=-1`, { page: 0 })
        .then((res) => {
          dispatch(fetchBinAction(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchMeetings(): Effect {
  const topOfTheHour = dayjs();
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(
          `meetings?$populate=host&$populate=invitedUsers&endDate[$gte]=${topOfTheHour.toISOString()}`,
          { page: 0 }
        )
        .then((res) => {
          dispatch(fetchMeetingsAction(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateMeeting(data): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .post(`meetings`, data)
        .then((res) => {
          dispatch(FetchMeetings());
        })
        .catch((err) => {
          new Error(err);
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );
          dispatch(loadingAction(false));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function EditMeeting(id: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`meetings/${id}`, data)
        .then((res) => {
          dispatch(FetchMeetings());
        })
        .catch((err) => {
          new Error(err);
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );
          dispatch(loadingAction(false));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteMeeting(id): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .delete(`meetings/${id}`)
        .then(() => {
          dispatch(FetchMeetings());
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeclineMeeting(meeting: IMeeting): Effect {
  return async function (dispatch, getState) {
    try {
      dispatch(loadingAction(true));
      let { _id, invitedUsers } = meeting;
      const userId = getState().mainStore.userId;
      let users = invitedUsers.filter((user) => user._id !== userId);

      api
        .patch(`meetings/${_id}`, {
          invitedUsers: [...users.map((user) => user._id)],
        })
        .then(() => {
          dispatch(FetchMeetings());
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteFromBin(id): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .delete(`bin/${id}`)
        .then(() => {
          dispatch(FetchBin());
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function RestorefromBin(id): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`bin/${id}?restore=true`);
      dispatch(FetchBin());
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchNotifications(): Effect {
  return async function (dispatch) {
    const environment = localStorage.getItem("environment");
    try {
      dispatch(loadingAction(true));
      api
        .get(
          environment === "development"
            ? `https://cloudqa.javat365.com/api/system-messages`
            : `https://cloud.javat365.com/api/system-messages`
        )
        .then((res) => {
          dispatch(fetchNotificationsAction(res.data.messages));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchSiteGroups(): Effect {
  return async function (dispatch, getState) {
    try {
      const userId = getState().mainStore.userId;
      dispatch(loadingAction(true));
      const res = await api.get(`users/${userId}`);
      if (res.data.role.includes("admin")) {
        console.log("Is admin");
        api
          .get(`groups`, {
            page: 0,
            limit: 100,
            "$sort[createdAt]": -1,
          })
          .then((res) => {
            console.log(res.data);
            dispatch(
              fetchSiteGroupsAction(
                res.data.data.map((item) => ({ ...item, role: "admin" }))
              )
            );
          })
          .catch((err) => new Error(err));
      } else {
        api
          .get(`group-members?$populate=group&user=${userId}`, {
            page: 0,
            limit: 100,
            "$sort[createdAt]": -1,
          })
          .then((res) => {
            dispatch(
              fetchSiteGroupsAction(
                res.data.data.map((item) => ({
                  ...item.group,
                  role: item.role,
                }))
              )
            );
          })
          .catch((err) => new Error(err));
      }
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchGroupRequests(groupId?: string): Effect {
  return async function (dispatch) {
    try {
      const moreQuery = groupId ? { group: groupId } : null;
      dispatch(loadingAction(true));
      api
        .get(
          `group-join-requests?$populate=group&$populate=user&status=requested`,
          moreQuery
        )
        .then((res) => {
          dispatch(loadingAction(false));
          dispatch(fetchGroupRequestsAction(res.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchCreateGroupRequests(): Effect {
  return async function (dispatch, getState) {
    try {
      const isAdmin = getState().mainStore.isAdmin;
      const userId = getState().mainStore.userId;
      dispatch(loadingAction(true));
      api
        .get(`group-create-requests?$populate=requestedBy&status=pending`, {
          "$sort[createdAt]": -1,
          ...(isAdmin ? {} : { requestedBy: userId }),
        })
        .then((res) => {
          dispatch(loadingAction(false));
          dispatch(fetchCreateGroupRequestsAction(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchGroupDocuments(id: string, folder_id?: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      const filesQuery = () => {
        const data = {
          group: id,
          $populate: ["owner", "editingUser", "lastModifiedBy"],
          "$sort[createdAt]": -1,
        };

        if (folder_id) data.parent = folder_id;

        return data;
      };

      api
        .get(`files`, filesQuery())
        .then((res) => {
          dispatch(fetchGroupDocuments(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err, "feting file error ");
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchGroupDraftDocuments(
  id: string,
  folder_id?: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      api
        .get(`file-update`, {
          group: id,
          $populate: ["owner", "editingUser", "lastModifiedBy"],
          action: "getGroupDraftDocument",
        })
        .then((res) => {
          dispatch(fetchGroupDraftDocuments(res.data.data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err, "feting file error ");
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function SearchDocuments(id: string, query?: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`file-update`, {
          $populate: "group parent owner",
          group: id,
          action: "getAllGroupFiles",
        })
        .then((res) => {
          function removeDeleted() {
            let isDeleted = false;
            res.data.data.forEach((el, i) => {
              if (el.deleted) {
                el.deleted = true;
                res.data.data.forEach((el2) => {
                  if (el._id === el2.parent?._id && !el2.deleted) {
                    el2.deleted = true;
                    isDeleted = true;
                  }
                });
              }
            });
            if (isDeleted) {
              removeDeleted();
            }
          }
          removeDeleted();
          const data = res.data.data.filter(
            (el) =>
              el.name.toLowerCase().includes(query?.toLowerCase()) &&
              !el.deleted
          );
          dispatch(fetchGroupDocuments(data));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteDocument(id, doc_id, folder_id?: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .delete(`files/${doc_id}`)
        .then((res) => {
          console.log(res);
          dispatch(FetchGroupDocuments(id, folder_id));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function RenameDocument(id, doc_id, name, folder_id?: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`files/${doc_id}`, { name })
        .then((res) => {
          dispatch(FetchGroupDocuments(id, folder_id));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteDocuments(id, ids, folder_id?: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await Promise.all(
        ids.map((_id) => {
          return api.delete(`files/${_id}`);
        })
      );
      dispatch(FetchGroupDocuments(id, folder_id));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UploadDocument(
  groupId: string,
  File: any,
  folder_id: string,
  userId?: string,
  openFile? = false // added to open file in new tab as created
): Effect {
  return async function (dispatch) {
    const formData = new FormData();
    formData.append("group", groupId);
    formData.append("parent", folder_id);
    if (File.length > 1) {
      for (let file of File) {
        formData.append("file", file);
      }
    } else {
      formData.append("file", File);
    }
    const files = Array.from(File);
    const fileUrls = await uploadFiles(files);
    try {
      dispatch(loadingAction(true));
      const { data } = await api.post(
        `files`,
        files.map((file, index) => ({
          group: groupId,
          name: file.name,
          parent: folder_id || undefined,
          type: "file",
          url: userId ? "" : fileUrls[index],
          draftUrl: userId ? fileUrls[index] : "",
          editingUser: userId ? userId : null,
        }))
      );
      if (openFile) {
        window.open(
          `/siteGroups/${groupId}/file/${
            data[0]._id
          }/edit?type=${getTypeOfFileFormat(data[0].name)}`
        );
      }
      dispatch(FetchGroupDocuments(groupId, folder_id));
    } catch (err) {
      console.error(err.message);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function createFolder(
  groupId: string,
  folder_id: string,
  name: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      console.log(groupId, folder_id || undefined, name);

      await api.post(`files`, {
        group: groupId,
        name,
        type: "folder",
        parent: folder_id || undefined,
      });
      dispatch(FetchGroupDocuments(groupId, folder_id));
    } catch (err) {
      console.error(err.message);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchUserGroups(id, currentUserId, setOpenModal?): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`group-members?user=${id}&$populate[0]=user&$populate[1]=group`, {
          page: 0,
        })
        .then((res) => {
          const { data } = res;
          const result = data.data?.filter((el) => !el.group.deleted);
          dispatch(fetchUserGroupsAction(result));
          if (setOpenModal) setOpenModal(true);
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteUserFromGroup(
  groupMemberDocId,
  userId,
  currentUserId
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`group-members/${groupMemberDocId}`).then((res) => {
        dispatch(FetchUserGroups(userId, currentUserId));
        dispatch(fetchUserCachesAction({}));
        message.success({
          content: "User deleted successfully.",
        });
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateGroup(
  data: any,
  key: any,
  btn: any,
  isAdmin: any
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      if (isAdmin) {
        api
          .post(`groups`, data)
          .then(async (res) => {
            dispatch(FetchSiteGroups());
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.message
                ? err.response.data.message
                : err.message
            );
            new Error(err);
            dispatch(loadingAction(false));
          });
      } else {
        api
          .post(`group-create-requests`, data)
          .then(async (res) => {
            notification.open({
              message: "",
              description: "Group has now been sent for approval",
              btn,
              key,
            });
            dispatch(loadingAction(false));
          })
          .catch((err) => {
            message.error(
              err?.response?.data?.message
                ? err.response.data.message
                : err.message
            );
            new Error(err);
            dispatch(loadingAction(false));
          });
      }
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchSiteGroup(groupId: string): Effect {
  return async function (dispatch, getState) {
    try {
      dispatch(loadingAction(true));
      const userId = getState().mainStore.userId;
      const { data } = await api.get(`groups/${groupId}`, {
        $populate: "tags",
      });
      const response = data;
      dispatch(loadingAction(false));
      dispatch(
        fetchSiteGroupAction({
          ...response,
          isOwner: response.role === "owner",
          // tags: [],
          owners: [],
        })
      );
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateGroup(groupId: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`groups/${groupId}`, data)
        .then((res) => {
          console.log(res, "  fetched one group by id ");
          dispatch(FetchSiteGroup(groupId));
          dispatch(FetchMembers(groupId));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateGroupRequest(requestId: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`group-join-requests/${requestId}`, {
          status: data.status,
          comment: data.comment,
        })
        .then((res) => {
          dispatch(FetchGroupRequests());
        })
        .catch((err) => console.log(err) /*new Error(err)*/);
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateGroupCreateRequest(requestId: string, data: any): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`group-create-requests/${requestId}`, {
          status: data.status,
          reason: data.comment,
        })
        .then((res) => {
          dispatch(FetchCreateGroupRequests());
        })
        .catch((err) => console.log(err) /*new Error(err)*/);
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchFeedItems(groupId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`posts`, {
          group: groupId,
          "$sort[createdAt]": -1,
          $populate: ["author", "lastModifiedBy"],
        })
        .then((res) => {
          dispatch(fetchFeedItemsAction(res.data.data));
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          window.history.back();
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchFeedDocument(groupId: string, feedItemId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`files/${feedItemId}`, { $populate: "editingUser" })
        .then((res) => {
          dispatch(fetchFeedItemAction(res.data));
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          window.history.back();
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function FetchSharedFeedItems(groupId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`posts?sharedWith=${groupId}&$populate=group&$populate=sharedWith`)
        .then((res) => {
          dispatch(fetchSharedFeedItemsAction(res.data.data));
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          console.log(err, " fetch shared feed error ");
          return;
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ClearFeedItem(): Effect {
  return async function (dispatch) {
    dispatch(fetchFeedItemAction(undefined));
  };
}

export function FetchFeedItem(groupId: string, feedItemId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .get(`posts/${feedItemId}`)
        .then((res) => {
          dispatch(fetchFeedItemAction(res.data));
        })
        .catch((err) => {
          dispatch(loadingAction(false));
          window.history.back();
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteFeedItem(groupId: string, feedItemId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`posts/${feedItemId}`).then((res) => {
        dispatch(FetchFeedItems(groupId));
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ShareFeedItem(
  siteGroups: string[],
  feedItemId: string,
  setModalVisible: any
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      setModalVisible(false);
      await api
        .patch(`posts/${feedItemId}`, {
          sharedWith: [...siteGroups],
        })
        .then((res) => {
          console.log(res, " response data ");
          message.success({
            content: "Content shared successful",
          });
          window.location.reload();
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateSurvey(
  id: string,
  survey: any,
  history: History
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api
        .post(`posts`, {
          ...survey,
          group: id,
          status: "published",
          type: "survey",
        })
        .then((res) => {
          message.success({
            content: "Survey creation successful",
          });
          history.push(`/siteGroups/${id}`);
        });
    } catch (err) {
      console.error(err);
      if (err?.response?.data?._message?.includes("SurveyAnswers")) {
        message.error("Error: Survey Answers not filled");
      } else if (err?.response?.data?._message?.includes("SurveyQuestions")) {
        message.error("Error: Survey Question not filled.");
      } else {
        message.error(
          err?.response?.data?.message ? err.response.data.message : err.message
        );
      }

      dispatch(loadingAction(false));
    }
  };
}

interface UnsharedGroupProps {
  groupIds: string[];
  allGroudIds?: any[];
  feedItemId: string;
  setModalVisible?: any;
}

export function UnshareGroup({
  groupIds,
  feedItemId,
  allGroudIds,
  setModalVisible,
}: UnsharedGroupProps): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      if (setModalVisible) {
        setModalVisible(false);
      }
      console.log(groupIds, " group ids to remove ");
      let sharedWith = allGroudIds?.filter(
        (id) => id !== groupIds.find((cid) => cid === id)
      );
      // only the normal group left
      if (sharedWith?.length === 1) {
        sharedWith = [];
      }
      await api.patch(`posts/${feedItemId}`, { sharedWith }).then((res) => {
        window.location.reload();
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function DeleteGroup(groupId: string, history: History): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.delete(`groups/${groupId}`);
      history.push("/siteGroups");
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function CreateFeedItem(
  groupId: string,
  data: any,
  publish: boolean,
  history: History,
  path: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      const query = new URLSearchParams();
      query.set("publish", publish);
      let status = "draft";
      if (publish) status = "published";

      api
        .post(`posts`, { ...data, group: groupId, type: "article", status })
        .then((res) => {
          console.log(res, " feed item created");
          dispatch(loadingAction(false));
          history.push(path);
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UpdateFeedItem(
  groupId: string,
  feedItemId: string,
  data: any,
  history: History,
  path: string
): Effect {
  return async function (dispatch) {
    try {
      api
        .patch(`posts/${feedItemId}`, data)
        .then((res) => {
          dispatch(loadingAction(false));
          history.push(path);
        })
        .catch((err) => {
          console.log(err, " new err");
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function AutoCompleteContentImport(query: string): Effect {
  return async function (dispatch) {
    try {
      api
        .post(`microsoft`, { intent: "autocomplete", data: query })
        .then((res) => {
          dispatch(fetchContentCachesAction(res.data.data));
          dispatch(loadingAction(false));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );
          dispatch(loadingAction(false));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function AutoCompleteUser(query: string): Effect {
  return async function (dispatch) {
    try {
      //dispatch(loadingAction(true));
      api
        .get(`/user-search`, { params: { search: query } })
        .then((res) => {
          dispatch(fetchUserCachesAction(res.data));
          dispatch(loadingAction(false));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );
          dispatch(loadingAction(false));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ValidateContentImport(groupId: string, url: string): Effect {
  return async function (dispatch) {
    const msalToken = (await authService.getMSALToken()) || "";
    if (!msalToken) {
      message.error("Invalid office 365 credentails. please re-authenticate");
    }

    try {
      dispatch(loadingAction(true));
      api
        .post(`microsoft`, {
          intent: "import-content-validate",
          data: {
            url: url,
            msalToken: msalToken,
            id: groupId,
          },
        })
        .then((res) => {
          dispatch(fetchContentCacheAction(res.data.data));
          dispatch(loadingAction(false));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );
          dispatch(loadingAction(false));
        });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}
export function ImportContent(groupId: string, contentCacheId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .post(`microsoft`, {
          intent: "import-content",
          data: {
            contentCacheId,
            id: groupId,
          },
        })
        .then((res) => {
          console.log(res.data);
          dispatch(fetchFeedItemAction(res.data));
          dispatch(FetchFeedItems(groupId));
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function UploadContent(
  groupId: string,
  File: any,
  history: History,
  status?: string = "draft"
): Effect {
  return async function (dispatch) {
    try {
      let name = File.name;
      dispatch(loadingAction(true));
      const uploadedFile = await uploadFiles([File]);

      api
        .post(`posts`, {
          group: groupId,
          type: "document",
          title: name,
          attachments: [{ name, source: uploadedFile[0], type: "document" }],
          status,
        })
        .then((res) => {
          dispatch(FetchFeedItems(groupId));
          history.push(`/siteGroups/${groupId}`);
        })
        .catch((err) => new Error(err));
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export function ClearContentCache(): Effect {
  return async function (dispatch) {
    dispatch(fetchContentCacheAction(null));
  };
}

export function TogglePublishedContent(
  groupId: string,
  action: string,
  objectId: string,
  type: string
): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`posts/${objectId}`, { status: action })
        .then((res) => {
          dispatch(FetchFeedItems(groupId));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );

          dispatch(loadingAction(false));
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function ApproveRequests(groupId: string, requestId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`group-join-requests/${requestId}`, { status: "approved" })
        .then((res) => {
          dispatch(FetchSiteGroup(groupId));
          dispatch(FetchGroupRequests(groupId));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );

          dispatch(loadingAction(false));
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function RejectRequests(groupId: string, requestId: string): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      api
        .patch(`group-join-requests/${requestId}`, { status: "denied" })
        .then((res) => {
          dispatch(FetchSiteGroup(groupId));
          dispatch(FetchGroupRequests(groupId));
        })
        .catch((err) => {
          message.error(
            err?.response?.data?.message
              ? err.response.data.message
              : err.message
          );

          dispatch(loadingAction(false));
          new Error(err);
        });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function FetchTenant(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      const organization = await api.get(`organization`);

      dispatch(fetchTenantAction(organization.data));
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function FetchConfig(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      await api.get(`configuration`).then((res) => {
        dispatch(fetchConfigAction(res.data));
        localStorage.setItem(
          "primaryColor",
          res.data.primaryColor || "#1890ff"
        );
        localStorage.setItem("environment", res.data.environment);
      });
    } catch (err) {
      console.error(err);
      dispatch(loadingAction(false));
    }
  };
}

export function DisconnectTenant(): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));

      api.post(`microsoft`, { intent: "deactivate" }).then((res) => {
        dispatch(FetchConfig());
      });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
      dispatch(loadingAction(false));
    }
  };
}

export function UpdateConfiguration(data: any, file = []): Effect {
  return async function (dispatch) {
    try {
      dispatch(loadingAction(true));
      if (file?.length) {
        const uploadedFile = await uploadFiles(Array.from(file));
        data.logo = uploadedFile[0];
      }
      await api.patch(`configuration`, data).then((res) => {
        dispatch(FetchConfig());
      });
    } catch (err) {
      console.error(err);
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );

      dispatch(loadingAction(false));
    }
  };
}

export const checkIfFileIsEditingByOtherUser = (
  fileId: string,
  userId: string
) => {
  return async function (dispatch) {
    try {
      api
        .get("file-update", { fileId, userId })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
    }
  };
};

export const moveFileIntoFolderOrFolderIntoFolder = (
  source: IFeedItem,
  destination: IFeedItem,
  groupId: string,
  folderId: string
) => {
  return async function (dispatch) {
    try {
      if (
        (source.type == "file" && destination.type === "file") ||
        (source.type == "folder" && destination.type === "file") ||
        source._id === destination._id
      )
        return;
      await api.patch(`files/${source._id}`, {
        parent: destination._id,
      });
      dispatch(FetchGroupDocuments(groupId, folderId));
    } catch (err) {
      message.error(
        err?.response?.data?.message ? err.response.data.message : err.message
      );
    }
  };
};
