type UserData = string[];

type SetDataFunction = (data: UserData[]) => void;

const usersFromCSV = (file: File, setData: SetDataFunction) => {
	let fileReader = new FileReader();
	fileReader.onload = function (ev: ProgressEvent<FileReader>) {
		if (!ev.target) return

		let textData: string[] = String(ev.target.result).split("\n");
		let mappedText = textData.map((text) =>
			text.split(",").map((t) => t.trim())
		);

		mappedText.shift()
		setData(mappedText);
	};
	fileReader.readAsText(file as File);
};

export default usersFromCSV;
