import { Input } from "antd";
import React from "react";

const Search = Input.Search;

export const SearchBox = ({ onSearch, placeholder, filtered, searchProps, width, ...props }: any) => (
  <div style={{ display: "flex", alignItems: "flex-end" }} {...props}>
    <Search
      allowClear
      className="search-input"
      placeholder={placeholder}
      onSearch={onSearch}
      style={{ width: width || 200 }}
      {...searchProps}
    />
  </div>
);
