import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Alert, Menu } from "antd";
import React, { SetStateAction, useEffect } from "react";
import Joyride, { STATUS } from "react-joyride";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { matchRoutes } from "react-router-config";
import { Link, useLocation } from "react-router-dom";

import getRoutes from "../routes";
import { FetchConfig, FetchStats, UpdateUser } from "../store/Effects";
import { AppStateType } from "../types";

interface Props {
  collapsed: boolean;
  drawerClose?: (value: SetStateAction<boolean>) => void;
}

const Tooltip = ({
  closeProps,
  index,
  isLastStep,
  step,
  backProps,
  primaryProps,
  skipProps,
  tooltipProps,
}: any) => {
  return (
    <div
      style={{ background: "#f0f2f5", minWidth: "300px", maxWidth: "350px" }}
      className="position-relative"
      {...tooltipProps}
    >
      {step.title && <h6 className="px-4 text-center pt-4">{step.title}</h6>}
      {step.content && (
        <div className={step.title ? "" : "py-2"}>
          <h6 className="px-4 text-center py-4">{step.content}</h6>
        </div>
      )}
      <div className="bg-white d-flex justify-content-between align-items-center px-3 py-3">
        {!isLastStep && (
          <LeftOutlined
            style={{
              fontSize: "30px",
              color: "#08c",
              visibility: index > 0 ? "visible" : "hidden",
              strokeWidth: "70px",
            }}
            {...backProps}
          />
        )}
        {!isLastStep ? (
          <span {...skipProps}>SKIP</span>
        ) : (
          <span style={{ margin: "0 auto" }} {...closeProps}>
            CLOSE
          </span>
        )}
        {!isLastStep ? (
          <RightOutlined style={{ fontSize: "30px", color: "#08c" }} {...primaryProps} />
        ) : (
          <span />
        )}
      </div>
    </div>
  );
};

const steps = [
  {
    target: ".dashboard-page",
    disableBeacon: true,
    title: "Welcome to JAVAT 365",
    placement: "center",
    content: "Here are quick tips to get started",
  },
  {
    target: ".users",
    content: "Upload your users",
  },
  {
    target: ".help-message",
    content: "View quick Pro-Tips to get you going",
  },
  {
    target: ".settings",
    content: "Setup your tenant",
  },
  {
    target: ".admin",
    content: "Visit your admin center",
  },
  {
    target: ".helpMenu",
    content: "For FAQs, review your super admin guide in the help section",
  },
];

const userSteps = [
  {
    target: ".dashboard-page",
    disableBeacon: true,
    content: "Welcome to JAVAT 365",
    placement: "center",
  },
  {
    target: ".groups",
    content: "Create your first group",
  },
  {
    target: ".helpMenu",
    content: "For FAQs review the guides in the help section",
  },
  {
    target: ".dashboard-page",
    content: "Contact your site administrator for more support",
    placement: "center",
  },
];

const Navigation: React.FC<Props> = ({ collapsed, drawerClose }) => {
  let location = useLocation();
  const primaryColor = localStorage.getItem("primaryColor") || "#1890ff";
  const [onboarded, setOnboarded] = React.useState(false);

  let configurations = useSelector(
    (state: AppStateType) => state.mainStore.configurations,
    shallowEqual
  );

  let tenant = useSelector(
    (state: AppStateType) => state.mainStore.tenant,
    shallowEqual
  );


  const subscriptionStats = useSelector(
    (state: AppStateType) => state.mainStore.stats.subscription,
    shallowEqual
  );

  const defaultLocation = (): string[] => {
    if (location.pathname.length === 1) return ["/"];
    if (location.pathname.length > 1) return [location.pathname.split("/")[1]];
    return [];
  };
  const isAdmin = useSelector((state: AppStateType) => state.mainStore.isAdmin, shallowEqual);
  const user = useSelector((state: AppStateType) => state.mainStore.user, shallowEqual);
  const isSuperAdmin = useSelector(
    (state: AppStateType) => state.mainStore.isSuperAdmin,
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(FetchConfig());
    dispatch(FetchStats());
  }, [dispatch]);

  const handleJoyrideCallback = (data: any) => {
    if(user?.onboarded || onboarded) return;
    const { status } = data;
    dispatch(UpdateUser(user?._id!, { onboarded: true }, isAdmin));
    if ([STATUS.FINISHED].includes(status)) {
      setOnboarded(true)
      dispatch(UpdateUser(user?._id!, { onboarded: true }, isAdmin));
    }
  };

  return (
    <Menu defaultSelectedKeys={defaultLocation()} mode="inline" className="navigation">
      <div className="menu-logo">
        {collapsed ? (
          <a href="/">
            <img src={require("../assets/images/logo.png")} alt="Logo" />
          </a>
        ) : (
          <a href="/">
            <img
              style={{ width: "auto", height: "60px" }}
              alt="Logo"
              src={
                 configurations?.logo || require("../assets/images/logo-inverse.png")
              }
            />
          </a>
        )}
      </div>

      {!collapsed && <div className={"menu-heading"}>MANAGE</div>}

      <Menu.Item
        key="/"
        title="Dashboard"
        onClick={drawerClose ? () => drawerClose(false) : undefined}
      >
        <Link to="/">
          <div className="menu-item">
            <i className="pe-7s-graph2"></i>
            {!collapsed && <span>Dashboard</span>}
          </div>
        </Link>
      </Menu.Item>


      {subscriptionStats.subscription_status === "active" && (
        <>
          {user && (
            <Joyride
              showProgress={true}
              showSkipButton={true}
              callback={handleJoyrideCallback}
              styles={{
                options: {
                  backgroundColor: "#f0f2f5",
                  arrowColor: "#f0f2f5",
                  primaryColor,
                  textColor: "#000",
                },
              }}
              continuous
              run={!user?.onboarded && !collapsed}
              tooltipComponent={Tooltip}
              beaconComponent={() => <div style={{ display: "none" }} />}
              // @ts-ignore
              steps={isAdmin ? steps : userSteps}
            />
          )} 
          <Menu.Item
            key="siteGroups"
            title="Groups"
            onClick={drawerClose ? () => drawerClose(false) : undefined}
          >
            <Link to="/siteGroups">
              <div className="menu-item groups">
                <i className="pe-7s-folder"></i>
                {!collapsed && <span>Groups</span>}
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="meeting"
            title="Meetings"
            onClick={drawerClose ? () => drawerClose(false) : undefined}
          >
            <Link to="/meeting">
              <div className="menu-item">
                <i className="pe-7s-date"></i>
                {!collapsed && <span>Meetings</span>}
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="groupRequests"
            title="Group Requests"
            onClick={drawerClose ? () => drawerClose(false) : undefined}
          >
            <Link to="/groupRequests">
              <div className="menu-item">
                <i className="pe-7s-bell"></i>
                {!collapsed && <span>Requests</span>}
              </div>
            </Link>
          </Menu.Item>
          {configurations?.domain && user?.username && (
            <Menu.Item
              key="admin"
              title="Open MailBox"
              onClick={drawerClose ? () => drawerClose(false) : undefined}
            >
              <a rel="noopener noreferrer" target="_blank" href="https://mail.javat365.com">
                <div className="menu-item">
                  <i className="pe-7s-mail-open"></i>
                  {!collapsed && <span>Open MailBox </span>}
                </div>
              </a>
            </Menu.Item>
          )}
          {isAdmin && !collapsed && <div className={"menu-heading"}>ADMINISTRATOR</div>}
          <Menu.Item
            key="activities"
            title="Activities"
            onClick={drawerClose ? () => drawerClose(false) : undefined}
          >
            <Link to="/activities">
              <div className="menu-item">
                <i className="pe-7s-news-paper"></i>
                {!collapsed && <span>Activities</span>}
              </div>
            </Link>
          </Menu.Item>
          <Menu.Item
            key="bin"
            title="Recycle Bin"
            onClick={drawerClose ? () => drawerClose(false) : undefined}
          >
            <Link to="/bin">
              <div className="menu-item">
                <i className="pe-7s-trash"></i>
                {!collapsed && <span>Recycle Bin</span>}
              </div>
            </Link>
          </Menu.Item>

          {isAdmin && (
            <>
              <Menu.Item
                key="users"
                title="Manage Users"
                onClick={drawerClose ? () => drawerClose(false) : undefined}
              >
                <Link to="/users">
                  <div className="menu-item users">
                    <i className="pe-7s-users"></i>
                    {!collapsed && <span>Internal Users</span>}
                  </div>
                </Link>
              </Menu.Item>

              <Menu.Item
                key="tags"
                title="Tags"
                onClick={drawerClose ? () => drawerClose(false) : undefined}
              >
                <Link to="/tags">
                  <div className="menu-item tags">
                    <i className="pe-7s-drawer"></i>
                    {!collapsed && <span>User Tags</span>}
                  </div>
                </Link>
              </Menu.Item>
              {isSuperAdmin && user && (
                <>
                  <Menu.Item
                    key="settings"
                    title="Settings"
                    onClick={drawerClose ? () => drawerClose(false) : undefined}
                  >
                    <Link className="settings" to="/settings">
                      <div className="menu-item settings">
                        <i className="pe-7s-settings"></i>
                        {!collapsed && <span>Settings</span>}
                      </div>
                    </Link>
                  </Menu.Item>
                  <Menu.Item
                    key="admin"
                    title="Admin Center"
                    onClick={drawerClose ? () => drawerClose(false) : undefined}
                  >
                    <a
                      className="admin"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={
                        configurations?.environment === "development"
                          ? `http://cloudqa.javat365.com/sso-login?email=${user.email}&token=${user.ssoToken}&tenant=${tenant?.slug}`
                          : `http://cloud.javat365.com/sso-login?email=${user.email}&token=${user.ssoToken}&tenant=${tenant?.slug}`
                      }
                    >
                      <div className="menu-item">
                        <i className="pe-7s-next-2"></i>
                        {!collapsed && <span>Admin Center</span>}
                      </div>
                    </a>
                  </Menu.Item>
                </>
              )}
            </>
          )}
        </>
      )}
    </Menu>
  );
};

const Breadcrumb = ({ locationPath, onMatchedRoutes }: any) => {
  const isSuperAdmin = useSelector(
    (state: AppStateType) => state.mainStore.isSuperAdmin,
    shallowEqual
  );
  const isAdmin = useSelector((state: AppStateType) => state.mainStore.isAdmin, shallowEqual);
  let matchedRoutes = matchRoutes(getRoutes({ isSuperAdmin }), locationPath);
  const dispatch = useDispatch();
  const subscriptionStats = useSelector(
    (state: AppStateType) => state.mainStore.stats.subscription,
    shallowEqual
  );

  if (typeof onMatchedRoutes === "function") {
    matchedRoutes = onMatchedRoutes(matchedRoutes);
  }

  useEffect(() => {
    dispatch(FetchStats());
  }, [dispatch]);

  const groupPathDetails = locationPath.split("/").filter((x: string) => x);

  return (
    <nav>
      <ol className="breadcrumb">
        <li key={"home"} className="breadcrumb-item">
          <Link to={"/"}>Home </Link>
        </li>
        {matchedRoutes.map((matchRoute: any, i: number) => {
          const { path, breadcrumbName } = matchRoute.route;
          const isActive = path === locationPath;

          return isActive ? (
            <li key={i} className="breadcrumb-item active">
              {breadcrumbName}
            </li>
          ) : (
            <li key={i} className="breadcrumb-item">
              <Link to={path}>{breadcrumbName} </Link>
            </li>
          );
        })}
        {groupPathDetails.length > 2 ? (
          <li className="breadcrumb-item">
            <Link to={`/${groupPathDetails[0]}/${groupPathDetails[1]}`}>Group's Content</Link>
          </li>
        ) : null}
      </ol>
      {isAdmin &&
        subscriptionStats.subscription_status &&
        subscriptionStats.subscription_status !== "active" && (
          <div style={{ margin: "1rem" }}>
            <Alert
              message={
                subscriptionStats.tenant_type === "manual" ? (
                  <p style={{ marginBottom: 0 }}>
                    <strong>Alert:</strong> Account is suspended, to reactivate please fill out the{" "}
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://forms.office.com/r/hn7W2uNR5Y"
                    >
                      subscription renewal form
                    </a>{" "}
                    to have an invoice generated and sent to your accounting team. Ensure your
                    invoice is fulfilled and payment confirmation is sent to accounting@javat365.com
                  </p>
                ) : (
                  <p style={{ marginBottom: 0 }}>
                    <strong>Alert:</strong> Account is suspended, to reactivate please visit your{" "}
                    <a
                      href="http://cloud.javat365.com/login?portal=login"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      Admin Center.
                    </a>
                  </p>
                )
              }
              type="warning"
              showIcon
            />
          </div>
        )}
    </nav>
  );
};

export { Navigation, Breadcrumb };
