import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/jquery/dist/jquery";
import "antd/dist/antd.css";

import { message } from "antd";
import React, { Suspense, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Link, Redirect, Route, Switch } from "react-router-dom";

import DefaultFallback from "./components/DefaultFallback";
import getRoutes from "./routes";
import * as lazyRoutes from "./routes.lazy";
import Layout from "./shared/Layout";
import { FetchCurrentUser } from "./store/Effects";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { AppStateType } from "../types";

message.config({
  top: 50,
  duration: 4,
  maxCount: 3,
});

const NotFound = () => (
  <div>
    <h1>404 - Not Found!</h1>
    <Link to="/">Go Home</Link>
  </div>
);

function App() {
  const token = useSelector(
    (state: any) => state.mainStore.token,
    shallowEqual
  );

  const dispatch = useDispatch();
  const isAuthenticated = token.length !== 0;

  useEffect(() => {
    if (isAuthenticated) dispatch(FetchCurrentUser());
  }, [dispatch, isAuthenticated]);

  const isSuperAdmin = useSelector(
    (state: any) => state.mainStore.isSuperAdmin,
    shallowEqual
  );

  const routes = React.useMemo(() => {
    return (
      <Switch>
        {getRoutes({ isSuperAdmin }).map(
          ({ private: isPrivate, ...route }, i) => {
            return isPrivate && !isAuthenticated ? (
              <Redirect key={i} to="/login" />
            ) : (
              <Route key={i} exact {...route}></Route>
            );
          }
        )}
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }, [isSuperAdmin, isAuthenticated]);

  return (
    <DndProvider backend={HTML5Backend}>
      <Suspense fallback={<DefaultFallback />}>
        <Switch>
          <Route path="/login">
            <lazyRoutes.Login />
          </Route>
          <Route path="/reset-password">
            <lazyRoutes.Reset />
          </Route>
          <Route path="/sso-login">
            <lazyRoutes.SSOLogin />
          </Route>
          <Route exact path={"/verify/:token"}>
            {isAuthenticated ? <Redirect to="/" /> : <lazyRoutes.Verify />}
          </Route>
          <Route path="/join-meeting/:org/:id">
            {isAuthenticated ? (
              <Layout>
                <lazyRoutes.JoinMeeting />{" "}
              </Layout>
            ) : (
              <lazyRoutes.JoinMeetingComponent />
            )}
          </Route>
          <Route path="*">
            <Layout>{routes}</Layout>
          </Route>
        </Switch>
      </Suspense>
    </DndProvider>
  );
}

export default App;
