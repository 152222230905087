// @ts-nocheck
import * as jose from "jose";

import {
  PDF_FILE_FORMATS,
  SPREADSHEET_FILE_FORMATS,
  WORD_FILE_FORMATS,
} from "../constants/index";
import AuthService from "../services/AuthService";
import { supportedDocumentType } from "../types/FileEditor";

const authService = new AuthService();
const baseUrl = "https://graph.microsoft.com/v1.0";

export async function getAuthImage(url: string): Promise<string> {
  const token = (await authService.getMSALToken()) || "";

  const headers = new Headers({ Authorization: `Bearer ${token}` });
  const options = {
    headers,
  };
  try {
    const response = await fetch(`${baseUrl}${url}`, options);
    const imageBlob = await response.blob();
    const reader = new FileReader();
    // reader.onloadend = () => reader.result as string
    // reader.readAsDataURL(imageBlob)
    return new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(imageBlob);
    });
  } catch (err) {
    throw new Error(err.text());
  }
}

// async getProfilePic(token: string) {
//     const headers = new Headers({ Authorization: `Bearer ${token}` });
//     const options = {
//       headers,
//     };
//     try {
//       const response = await fetch(`${this.graphUrl}/me/photo/$value`, options);
//       return response.blob()
//     } catch (err) {
//       throw new Error(err.text());
//     }
//   }

// export async function getUsersInTenant(path: string): Promise <any> {
//   const token = (await authService.getToken()) || "";
//   const headers = new Headers({ Authorization: `Bearer ${token}` });
//   const options = {
//     headers,
//   };
//   try {
//     const response = await fetch(`${baseUrl}${path}`, options);
//     return response
//   } catch(err) {
//     console.log('Geet Users in Tenant error: ', err.message)
//     return err
//   }
// }

// export async function getUserInTenant(path: string, id: string): Promise <any> {
//   const token = (await authService.getToken()) || "";
//   const headers = new Headers({ Authorization: `Bearer ${token}` });
//   const options = {
//     headers,
//   };
//   try {
//     const response = await fetch(`${baseUrl}${path}${id}`, options);
//     return response
//   } catch(err) {
//     console.log('Geet Users in Tenant error: ', err.message)
//     return err
//   }
// }

// export async function getUserInTenantPhoto(path: string, id: string, subPath: string): Promise <any> {
//   const token = (await authService.getToken()) || "";
//   const headers = new Headers({ Authorization: `Bearer ${token}` });
//   const options = {
//     headers,
//   };
//   try {
//     const response = await fetch(`${baseUrl}${path}${id}${subPath}`, options);
//     return response
//   } catch(err) {
//     console.log('Geet Users in Tenant error: ', err.message)
//     return err
//   }
// }

export async function graphRequest(path: string): Promise<any> {
  const token = (await authService.getMSALToken()) || "";
  const headers = new Headers({ Authorization: `Bearer ${token}` });
  const options = {
    headers,
  };
  try {
    const response = await fetch(`${baseUrl}${path}`, options);
    return response;
  } catch (err) {
    console.log("Geet Users in Tenant error: ", err.message);
    return err;
  }
}

const meetjwt =
  "77b6f6ae2786f548e5647c6be47718a58ef10d30f8200ba5b09c23dbbb313b45";
const url = process.env.REACT_APP_JITSI_URL;

export function generateMeetingToken(user, meetingId, subject) {
  const signJwt = new jose.SignJWT({
    context: {
      user: {
        avatar: user ? user.picture : "",
        name: user ? `${user.firstName} ${user.lastName}` : "",
        email: user ? user.email : "",
        event_id: 14,
        event_slug: meetingId,
      },
      iss: "5c13947ab3a69ad46ba8e9334a6abb17",
      aud: "5c13947ab3a69ad46ba8e9334a6abb17",
      room: meetingId,
      sub: process.env.REACT_APP_JITSI_URL,
    },
    iss: "5c13947ab3a69ad46ba8e9334a6abb17",
    aud: "5c13947ab3a69ad46ba8e9334a6abb17",
    room: meetingId,
    sub: "https://staging-meet4.javat365.com",
  });

  signJwt.setExpirationTime("24h");

  const token = signJwt.sign(meetjwt);

  return `${url}/${meetingId}?jwt=${token}#config.subject=${subject}`;
}

export const generateMeetingLink = (id) => {
  const tenantId = localStorage.getItem("tenantId");

  return `${process.env.REACT_APP_BASE_URL}/join-meeting/${tenantId}/${id}`;
};

export const getTypeOfFileFormat = (name: string):supportedDocumentType => {
  const fileExtension = name.split(".")[1];

  return PDF_FILE_FORMATS.includes(fileExtension)
    ? "pdf"
    : WORD_FILE_FORMATS.includes(fileExtension)
    ? "word"
    : SPREADSHEET_FILE_FORMATS.includes(fileExtension)
    ? "sheet"
    : null;
};
