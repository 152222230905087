import * as lazyRoutes from "./routes.lazy";

interface Props {
  isSuperAdmin?: boolean;
}

const getRoutes = ({ isSuperAdmin }: Props): any[] => {
  return [
    {
      path: `/`,
      component: lazyRoutes.Dashboard,
      exact: true,
      breadcrumbName: "Dashboard",
      private: true,
    },
    {
      path: `/tags`,
      component: lazyRoutes.Tags,
      exact: true,
      breadcrumbName: "Tags",
      private: true,
    },
    {
      path: `/siteGroups`,
      component: lazyRoutes.Groups,
      breadcrumbName: "Groups",
      private: true,
    },
    {
      path: `/siteGroups/:id`,
      component: lazyRoutes.GroupDetail,
      breadcrumbName: "Group Detail",
      private: true,
      exact: true,
    },
    {
      path: `/siteGroups/:id/editGroup`,
      component: lazyRoutes.GroupEditForm,
      breadcrumbName: "Edit Group",
      private: true,
    },
    {
      path: `/siteGroups/:id/createContent`,
      component: lazyRoutes.FeedItemForm,
      breadcrumbName: "Create content",
      private: true,
    },
    {
      path: `/siteGroups/:id/survey`,
      component: lazyRoutes.CreateSurvey,
      breadcrumbName: "Create Survey",
      private: true,
    },
    {
      path: `/siteGroups/:id/importContent`,
      component: lazyRoutes.ImportContentForm,
      breadcrumbName: "Import Content",
      private: true,
    },
    {
      path: `/siteGroups/:id/uploadContent`,
      component: lazyRoutes.UploadContentForm,
      breadcrumbName: "Import Content",
      private: true,
    },

    {
      path: `/siteGroups/:id/feedItems/:feedItemId`,
      component: lazyRoutes.FeedItemView,
      breadcrumbName: "View content",
      private: true,
    },
    {
      path: `/siteGroups/:id/feedItems/:feedItemId/edit`,
      component: lazyRoutes.FeedItemForm,
      breadcrumbName: "Edit content",
      private: true,
    },
    {
      path: `/siteGroups/:id/file/:fileId/edit`,
      component: lazyRoutes.FileEditor,
      breadcrumbName: "Edit content",
      private: true,
    },
    {
      path: `/activities`,
      private: true,
      component: lazyRoutes.Activities,
      breadcrumbName: "Activities",
    },
    {
      path: `/bin`,
      private: true,
      component: lazyRoutes.Bin,
      breadcrumbName: "Bin",
    },
    {
      path: `/meeting`,
      private: true,
      component: lazyRoutes.Meetings,
      breadcrumbName: "Meetings",
    },
    {
      path: `/settings`,
      private: true,
      component: lazyRoutes.Settings,
      breadcrumbName: "Settings",
      superAdminRoute: true,
    },
    {
      path: `/users`,
      private: true,
      component: lazyRoutes.ManageUsers,
      breadcrumbName: "Manage Users",
    },
    {
      path: `/groupRequests`,
      private: true,
      component: lazyRoutes.GroupRequests,
      breadcrumbName: "Requests",
    },
    {
      path: `/login`,
      cprivate: false,
      omponent: lazyRoutes.Login,
      breadcrumbName: "Sign in",
    },
    // { path: `/verify/:token`, cprivate: false, omponent: Verify, breadcrumbName: "Verify" },
  ];
};

export default getRoutes;
