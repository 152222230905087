import * as Msal from "msal";

import { userLogoutAction } from "../store/Actions";
import { store } from "../store/ConfigStore";

export default class AuthService {
  private graphScopes: string[] = [];
  private msal: Msal.UserAgentApplication;

  constructor() {
    let redirectUri = `https://clientqa.javat365.com`;


    this.graphScopes = [
      "user.read",

    ];

    this.msal = new Msal.UserAgentApplication({
      auth: {
        clientId: "ffb2585f-11ff-4334-bc65-3cd82bd16855",
        redirectUri: redirectUri,
      },
    });
  }

  async login() {
    return this.msal
      .loginPopup({
        scopes: this.graphScopes,
      })
      .then((data) => {
        console.log(data);

        const user: Msal.Account = this.msal.getAccount();
        if (user) {
          return user;
        } else {
          return null;
        }
      })
      .catch((err) => console.error(err));
  }

  logout() {
    this.msal.logout();
  }

  async getMSALToken() {
    return this.msal
      .acquireTokenPopup({ scopes: this.graphScopes })
      .then((data) => {
        console.log("silent-token-acquire...");
        return data.accessToken;
      })
      .catch((error) => {
        console.log("ERROR-silent-token-acquire...");
        console.error(error);
        return this.msal.acquireTokenPopup({ scopes: this.graphScopes }).then(
          (data) => {
            console.log("popup-token-acquire...", data);
            return data.accessToken;
          },
          (err) => {
            console.log("error fetching token");
            store.dispatch(userLogoutAction());
            // this.logout()
            throw new Error(err);
          }
        );
      });
  }
}
