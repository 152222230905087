import produce from "immer";

import { ActionsEnum, ActionsType, AppMainStore } from "../types";

export const initialState: AppMainStore = {
  isAdmin: false,
  isSuperAdmin: false,
  draftDocuments: [],
  isOwner: false,
  loading: false,
  userLoading: false,
  token: localStorage.getItem("token") || "",
  user: null,
  userId: localStorage.getItem("userId") || "",
  bin: [],
  meetings: [],
  meeting: {},
  eventsLog: [],
  notifications: [],
  siteGroups: [],
  groupRequests: [],
  groupCreateRequests: [],
  documents: [],
  tags: [],
  siteGroup: {
    createdAt: "",
    _id: "",
    avatar: "",
    title: "",
    createdDateTime: "",
    description: "",
    displayName: "",
    driveWebUrl: "",
    driverId: "",
    groupTypes: [],
    id: "",
    member: "",
    objectId: "",
    photo: "",
    siteId: "",
    siteWebUrl: "",
    updatedAt: "",
    visibility: "",
    isOwner: false,
    owners: [],
    tags: [],
    status: "pending",
    rejectionComment: "string",
    pendingSubscriptions: [],
  },
  contentCache: undefined,
  contentCaches: [],
  userCaches: [],
  userGroups: undefined,
  feedItem: undefined,
  feedItems: [],
  sharedFeedItems: [],
  users: [],
  tenant: null,
  configurations: {},
  tenantUsers: [],
  member: {
    active: false,
    createdAt: "",
    updatedAt: "",
    firstName: "",
    email: "",
    lastName: "",
    roles: [],
    siteGroups: [],
    objectId: "",
    phoneNumber: "",
    id: "",
  },
  members: [],
  notActiveUsers: [],
  admins: [],
  stats: {
    content: 0,
    totalNotification: 0,
    users: 0,
    groups: 0,
    seats: 0,
    subscription: {
      status: "",
      subscription_status: "active",
      expiry_date: 1636136051,
      tenant_type: "",
    },
  },
};

export function Reducer(state = initialState, action: ActionsType) {
  switch (action.type) {
    case ActionsEnum.LOADING:
      return produce(state, (draft) => {
        draft.loading = action.payload;
      });
    case ActionsEnum.USER_LOADING:
      return produce(state, (draft) => {
        draft.userLoading = action.payload;
      });
    case ActionsEnum.AUTH_SUCCESS:
      return produce(state, (draft) => {
        draft.token = action.payload.token;
        draft.user = action.payload.user;
        draft.isAdmin =
          action.payload.user?.role === "admin" ||
          action.payload.user?.role === "super-admin";
        draft.isSuperAdmin = action.payload.user?.role === "super-admin";
        draft.loading = false;
      });
    case ActionsEnum.LOGOUT:
      return produce(state, (draft) => {
        draft.token = "";
        draft.loading = false;
      });
    case ActionsEnum.FETCH_CURRENT_USER:
      return produce(state, (draft) => {
        draft.user = action.payload;
        draft.isAdmin =
          action.payload?.role === "admin" ||
          action.payload?.role === "super-admin";
        draft.isOwner = action.payload?.role === "owner";
        draft.isSuperAdmin = action.payload?.role === "super-admin";
        draft.loading = false;
      });
    case ActionsEnum.FETCH_CONFIG:
      return produce(state, (draft) => {
        // @ts-ignore
        draft.configurations = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_USERS:
      return produce(state, (draft) => {
        draft.users = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_ADMINS:
      return produce(state, (draft) => {
        draft.admins = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_MEMBERS:
      return produce(state, (draft) => {
        draft.members = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_STATS:
      return produce(state, (draft) => {
        draft.stats = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_EVENT_LOGS:
      return produce(state, (draft) => {
        draft.eventsLog = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_NOTIFICATIONS:
      return produce(state, (draft) => {
        draft.notifications = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_SITEGROUPS:
      return produce(state, (draft) => {
        draft.siteGroups = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_TAGS:
      return produce(state, (draft) => {
        draft.tags = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_GROUPREQUESTS:
      return produce(state, (draft) => {
        draft.groupRequests = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_NOTACTIVEUSERS:
      return produce(state, (draft) => {
        draft.notActiveUsers = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_GROUP_CREATE_REQUESTS:
      return produce(state, (draft) => {
        draft.groupCreateRequests = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_SITEGROUP:
      return produce(state, (draft) => {
        draft.siteGroup = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_FEED_ITEMS:
      return produce(state, (draft) => {
        draft.feedItems = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_SHARED_FEED_ITEMS:
      return produce(state, (draft) => {
        draft.sharedFeedItems = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_FEED_ITEM:
      return produce(state, (draft) => {
        draft.feedItem = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_CONTENT_CACHES:
      return produce(state, (draft) => {
        draft.contentCaches = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_CONTENT_CACHE:
      return produce(state, (draft) => {
        draft.contentCache = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_TENANT:
      return produce(state, (draft) => {
        // @ts-ignore
        draft.tenant = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_TENANT_USERS:
      return produce(state, (draft) => {
        draft.tenantUsers = action.payload;
        draft.loading = false;
        draft.userLoading = false;
      });
    case ActionsEnum.FETCH_USER_CACHES:
      return produce(state, (draft) => {
        draft.userCaches = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_USER_GROUPS:
      return produce(state, (draft) => {
        draft.userGroups = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_BIN:
      return produce(state, (draft) => {
        draft.bin = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_MEETINGS:
      return produce(state, (draft) => {
        draft.meetings = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_MEETING:
      return produce(state, (draft) => {
        draft.meeting = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_DOCUMENTS:
      return produce(state, (draft) => {
        draft.documents = action.payload;
        draft.loading = false;
      });
    case ActionsEnum.FETCH_DRAFT_DOCUMENTS:
      return produce(state, (draft) => {
        draft.draftDocuments = action.payload;
        draft.loading = false;
      });
    default:
      return state;
  }
}
