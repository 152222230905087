import React from "react";
import { BeatLoader } from "react-spinners";

const Loader: React.FC<{ userLoading?: boolean }> = ({ userLoading }) => {
  return (
    <div
      style={{
        position: "fixed",
        height: "100%",
        width: "100%",
        margin: "0 auto",
        top: 0,
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        zIndex: 2000,
        backgroundColor: "rgba(0, 0, 0, .6)",
      }}>
      {userLoading && (
        <div className="loading-card">
          <h5 style={{ zIndex: 201 }} className="mb-2">
            User synchronisation loading
          </h5>
        </div>
      )}
      <BeatLoader loading={true} color={"#ddd"} size={15} margin={3} />
    </div>
  );
};

export default Loader;
