import { CloudUploadOutlined, EditOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Tag } from "antd";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import CustomButton from "../components/Button";
import { UploadContent } from "../store/Effects";

interface Props {
  heading: string;
  subheading?: string;
  icon?: string;
  iconImage?: any;
  showCreateAction?: boolean;
  createActionLabel?: string;
  createBtnHanlde?: () => void;
  showEditBtn?: boolean;
  editBtnHandle?: () => void;
  deleteBtnHandle?: () => void;
  showImportBtn?: boolean;
  importBtnHandle?: () => void;
  importBtnCSVHandle?: any;
  handleUpdatePic?: () => void;
  uploadBtnHandle?: () => void;
  groupVisibitliy?: string;
  backgroundColor?: string;
  uploadBar?: any;
  searchInput?: any;
  showUploadBtn?: boolean;
  surveyImportBtn?: boolean;
  surveyBtnHandle?: () => void;
}

const PageTitle: React.FC<Props> = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { pathname } = useLocation();

  const dispatch = useDispatch();
  const [file, setFile] = useState<any>(null);
  const history = useHistory();
  const { id } = useParams<any>();

  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  useEffect(() => {
    setFile(null);
  }, [isModalVisible]);

  const handleOnchange = (e: any) => {
    if (e.target.files[0]?.size > 25000000) {
      message.error("File size must not exceed 25MB");
      return;
    }
    if (e.target.files[0]?.type !== "application/pdf") {
      message.error("File type must be pdf");
      return;
    }
    setFile(e.target.files);
  };

  return pathname.includes("editGroup") ||
    (pathname.startsWith("/siteGroups") && pathname.split("/").length === 3) ? (
    <div
      className="app-page-title"
      style={{ backgroundColor: props.backgroundColor || "rgba(255, 255, 255, 0.45)" }}
    >
      <div className="page-title-wrapper">
        <div className="page-title-heading">
          <div className={props.icon ? "page-title-icon" : "page-title-icon-image"}>
            {props.icon ? (
              <i className={props.icon}></i>
            ) : props.iconImage ? (
              <div className="logo-img-div">
                <img alt="" className="logo-img" src={props.iconImage} style={{ margin: "20" }} />
                {props.uploadBar}
              </div>
            ) : // <div></div>
            null}
          </div>
          <div>
            <h1>
              {props.heading}
              {props.groupVisibitliy ? (
                <Tag
                  style={{ marginLeft: "10px" }}
                  color={
                    props.groupVisibitliy.toUpperCase() === "PUBLIC"
                      ? "green"
                      : props.groupVisibitliy.toUpperCase() === "PRIVATE"
                      ? "red"
                      : ""
                  }
                >
                  {props.groupVisibitliy.toUpperCase()}
                </Tag>
              ) : null}
            </h1>
            <div className="page-title-subheading">{props.subheading}</div>
            {props.showEditBtn ? (
              <div className="page-title-actions">
                <Button type="default" htmlType="button" onClick={props.editBtnHandle}>
                  <EditOutlined />
                  Edit Group Details
                </Button>
                {props.deleteBtnHandle ? (
                  <Button
                    type="text"
                    danger={true}
                    htmlType="button"
                    onClick={(e) => {
                      e.preventDefault();
                      Modal.confirm({
                        title: "Delete group",
                        content: `Are you sure you want to delete this group`,
                        okText: "Delete",
                        okButtonProps: {
                          htmlType: "button",
                          danger: true,
                        },
                        onOk: () => {
                          // @ts-ignore
                          props.deleteBtnHandle();
                        },
                      });
                    }}
                  >
                    Delete
                  </Button>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
        <div className="title-actions">
          <Button.Group style={{ alignSelf: "flex-end" }}>
            {props.showCreateAction ? (
              <div className="page-title-actions">
                <CustomButton
                  className="button-action"
                  type="primary"
                  htmlType="button"
                  onClick={props.createBtnHanlde}
                >
                  <PlusOutlined />
                  {props.createActionLabel ? props.createActionLabel : "Create New"}
                </CustomButton>
              </div>
            ) : null}

            <Modal
              title=""
              visible={isModalVisible}
              onOk={(e: any) => {
                setIsModalVisible(false);
                dispatch(UploadContent(id, file[0], history, "published"));
                // window.history.back();
                setFile(null);
              }}
              okText="Upload and Publish"
              okButtonProps={{ disabled: !file }}
              onCancel={() => setIsModalVisible(false)}
            >
              <div
                className="import-content-form"
                style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}
              >
                <div className="page-container" style={{ marginBottom: "20px" }}>
                  <input
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    onChange={handleOnchange}
                    onClick={(event:any) => {
                      event.target.value = null
        }}
                    type="file"
                    accept=".pdf"
                  />
                  <Button
                    onClick={handleClick}
                    type="primary"
                    htmlType="button"
                    className="button-action"
                    style={{ border: "none", margin: "0 auto" }}
                  >
                    <UploadOutlined /> Click Here to select
                  </Button>
                  <i className="text-center mt-2 d-block">
                    PDF documents only, File size must not exceed <strong>25MB</strong>{" "}
                  </i>
                </div>

                {file && (
                  // <Card title=} bordered={false} style={{ marginTop: "10px" }}>
                  // <div>
                  //     <strong>File Name: </strong> <span></span>
                  //   </div>

                  //   <div>
                  //     <Button.Group style={{ marginTop: "20px" }}>
                  //       <Button
                  //         onClick={() => dispatch(UploadContent(id, file[0], history))}
                  //         style={{ marginRight: "10px" }}
                  //         type="primary">
                  //         Upload and Publish
                  //       </Button>
                  //       <Button onClick={() => setFile(null)} style={{ border: "1px solid red" }} type="default">
                  //         Reset
                  //       </Button>
                  //     </Button.Group>
                  //   </div>
                  // </Card>
                  <h2
                    style={{ fontSize: "1.1rem", textAlign: "center" }}
                  >{`File Name: ${file[0].name} `}</h2>
                )}
              </div>
            </Modal>

            <div style={{ display: "flex" }} className="page-title-actions">
              {props.showImportBtn && (
                <Button
                  type="default"
                  htmlType="button"
                  className="button-action"
                  onClick={props.importBtnHandle}
                  style={{ marginLeft: "13px", border: "none" }}
                >
                  <CloudUploadOutlined /> Import MS Content
                </Button>
              )}
              {props.showUploadBtn && (
                <Button
                  type="default"
                  htmlType="button"
                  className="button-action"
                  // onClick={props.uploadBtnHandle}
                  onClick={() => setIsModalVisible(true)}
                  style={{ marginLeft: "13px", border: "none" }}
                >
                  <UploadOutlined /> Upload Document
                </Button>
              )}
              {props.surveyImportBtn && (
                <Button
                  type="default"
                  htmlType="button"
                  className="button-action"
                  onClick={props.surveyBtnHandle}
                  style={{ marginLeft: "13px", border: "none" }}
                >
                  <CloudUploadOutlined /> Create Survey
                </Button>
              )}
            </div>
          </Button.Group>
          {props.searchInput}
        </div>
      </div>
    </div>
  ) : (
    <div className="page-header">
      <div>
        <h1>{props.heading}</h1>
        {props.searchInput}
      </div>
      <p style={{color:"red"}}>{props.subheading}</p>
      {(props.importBtnCSVHandle || props.showCreateAction || props.showImportBtn) && (
        <Button.Group style={{ alignSelf: "flex-end", marginBottom: "10px" }}>
          {props.importBtnCSVHandle && (
            <>
              <a
                style={{ alignSelf: "flex-end" }}
                className="d-flex ml-2"
                href="https://s3.us-east-2.amazonaws.com/com.javat365.content/JavatUsersImport.csv"
                rel="noopener noreferrer"
                target="_blank"
              >
                Download Template
              </a>
              {props.importBtnCSVHandle}
            </>
          )}
          {props.showCreateAction ? (
            <div className="page-title-actions ml-2">
              <CustomButton
                className="button-action"
                type="primary"
                htmlType="button"
                onClick={props.createBtnHanlde}
              >
                <PlusOutlined />
                {props.createActionLabel ? props.createActionLabel : "Create New"}
              </CustomButton>
            </div>
          ) : null}

          {props.showImportBtn ? (
            <div className="page-title-actions">
              <Button
                type="default"
                htmlType="button"
                onClick={props.importBtnHandle}
                // style={{ backgroundColor: "#FFCE67" }}
              >
                <CloudUploadOutlined /> Import MS Content
              </Button>
            </div>
          ) : null}
        </Button.Group>
      )}
    </div>
  );
};

export default PageTitle;
