export interface IUserModel {
	superAdmin: boolean;
	active: boolean;
	department: string;
	createdAt: string;
	updatedAt: string;
	firstName: string;
	username: string
	email: string;
	lastName: string;
	requestedBy?: IUserModel
	picture: string;
	roles: string[];
	role: string;
	_id: string;
	siteGroups: string[];
	businessPhones: string[];
	objectId: string;
	displayName: string;
	givenName: string;
	jobTitle: string;
	mail: string;
	phoneNumber: string;
	officeLocation: string;
	preferredLanguage: string;
	surname: string;
	userPrincipalName: string;
	id: string;
	ssoToken?: string;
	external?: boolean;
	userGroup: string;
	onboarded?: boolean;
	tags?: any[];
}

type IMemberUserProps = Record<
	| "createdAt"
	| "email"
	| "firstName"
	| "lastName"
	| "role"
	| "updatedAt"
	| "_id",
	string
>;

export interface IMember {
	user?: IMemberUserProps;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	firstName: string;
	email: string;
	lastName: string;
	roles: string[];
	role?: string;
	siteGroups: string[];
	objectId: string;
	phoneNumber: string;
	id: string;
	external?: boolean;
}

export interface IGroupMember {
  group: ISiteGroup;
  _id: string
  preferences: {
	muted: boolean
  };
  role: 'owner' | 'member' | 'moderator';
  user: IUserModel
  status?: 'pending' | 'approved' | 'declined'
  requestedBy?: IUserModel
  createdAt: string;
  updatedAt: string;
}

export interface IUsers extends Array<IUserModel> {}
export interface IMembers extends Array<IMember> {}

export interface IStats {
	users: number;
	content: number;
	totalNotification: number;
	groups: number;
	seats: number;
	subscription: ISubscriptionStats;
}

export interface IEventLogs {
	id: string;
	action: string;
	createdAt: string;
	title: string;
	updatedAt: string;
	user: any;
}

export interface ISiteGroup {
	createdAt: string;
	createdDateTime: string;
	description?: string;
	displayName?: string;
	driverId?: string;
	driveWebUrl?: string;
	groupTypes?: string[];
	id?: string;
	_id: string;
	avatar: string;
	title: string;
	member: string;
	objectId: string;
	desc?: string;
	deleted?: boolean
	photo: string;
	siteId: string;
	siteWebUrl: string;
	updatedAt: string;
	visibility: string;
	isOwner: boolean;
	owners: any[];
	tags: any[];
	status: string;
	rejectionComment: string;
	pendingSubscriptions: any[];
}

export interface IGroupRequest {
	_id: string;
	title: string
	requestedBy: IUserModel;
	status: "approved" | "denied" | "requested";
	createdAt: string;
	updatedAt: string;
}

export interface IGroupCreateRequests{
	_id: string;
	title: string
	user: IUserModel;
	status: "approved" | "denied" | "requested";
	createdAt: string;
	updatedAt: string;
}

export interface ITag {
	title: string;
	_id: string;
	description: string;
}

export interface IFeedItem {
	siteGroups: any[];
	// remove this later
	id: string;
	attachments: any[];
	sharedWith:ISiteGroup[]
	_id: string;
	siteId: string;
	driveId: string;
	objectId: string;
	type: string;
	name: string;
	title: string;
	webUrl: string;
	url: string;
	draftUrl: string;
	siteWebUrl: string;
	photo: string;
	body: string;
	summary: string;
	mimeType: string;
	size: number;
	questions: any[];
	pageLayout: string;
	createdDateTime: string;
	httpCode: number;
	siteGroup: string;
	status: string;
	createdAt: string;
	updatedAt: string;
}
export interface IFeedItems extends Array<IFeedItem> {}

export interface IContentCache {
	id: string;
	status: string;
	type: string;
	objectId: string;
	siteId: string;
	driveId: string;
	name: string;
	title: string;
	webUrl: string;
	url: string;
	createdAt: string;
	updatedAt: string;
}
export interface IContentCaches extends Array<IContentCache> {}

export interface IErrorMessage {
	httpCode: number;
	message: string;
}

export type INotification = {
	message: string;
	created_at: string;
	category: string;
};

// Store
export type AppMainStore = {
	loading: boolean;
	userLoading?: boolean;
	token: string;
	isAdmin: boolean;
	isOwner: boolean;
	isSuperAdmin: boolean;
	user: IUserModel | null;
	userId: string;
	users: IUserModel[];
	admins: IUserModel[];
	tenantUsers: IUserModel[];
	members: IMembers;
	member: IMember;
	notActiveUsers:  IUserModel[] | []
	stats: IStats;
	eventsLog: IEventLogs[];
	bin: IBin[];
	siteGroups: ISiteGroup[];
	groupRequests: IGroupRequest[];
	groupCreateRequests: IGroupCreateRequests[];
	tags: ITag[];
	notifications: INotification[];
	siteGroup: ISiteGroup;
	contentCache: any;
	userCaches: IUserModel[];
	userGroups: any;
	feedItem?: IFeedItem;
	feedItems: IFeedItem[];
	sharedFeedItems: IFeedItem[];
	documents: IDocument[];
	draftDocuments: IDocument[];
	tenant: any;
	meetings: IMeeting[];
	meeting: IMeeting;
	contentCaches: IContentCache[];
	configurations?: any;
};

export type IBin = any;
export type IMeeting = {
	[key: string]: any;
};

export type IDocument = {
	name: string;
};

// State
export type AppStateType = {
	mainStore: AppMainStore;
};

// sub
export type ISubscriptionStats = {
	status: string;
	subscription_status: string;
	tenant_type: string;
	expiry_date: number;
};

// Actions
type ActionMap<M extends { [index: string]: any }> = {
	[Key in keyof M]: M[Key] extends undefined
		? { type: Key }
		: { type: Key; payload: M[Key] };
};

export enum ActionsEnum {
	LOADING = "LOADING",
	FETCH_BIN = "FETCH_BIN",
	FETCH_MEETINGS = "FETCH_MEETINGS",
	USER_LOADING = "USER_LOADING",
	AUTH_SUCCESS = "AUTH_SUCCESS",
	LOGOUT = "LOGOUT",
	FETCH_CURRENT_USER = "FETCH_CURRENT_USER",
	FETCH_TENANT_USERS = "FETCH_TENANT_USERS",
	FETCH_USERS = "FETCH_USERS",
	FETCH_ADMINS = "FETCH_ADMINS",
	FETCH_MEMBERS = "FETCH_MEMBERS",
	FETCH_MEMBER = "FETCH_MEMBER",
	FETCH_STATS = "FETCH_STATS",
	FETCH_EVENT_LOGS = "FETCH_EVENT_LOGS",
	FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS",
	FETCH_SITEGROUPS = "FETCH_SITEGROUPS",
	FETCH_GROUPREQUESTS = "FETCH_GROUPREQUESTS",
	FETCH_NOTACTIVEUSERS = "FETCH_NOTACTIVEUSERS",
	FETCH_GROUP_CREATE_REQUESTS = "FETCH_GROUP_CREATE_REQUESTS",
	FETCH_SITEGROUP = "FETCH_SITEGROUP",
	FETCH_FEED_ITEM = "FETCH_FEED_ITEM",
	CLEAR_FEED_ITEM = "CLEAR_FEED_ITEM",
	FETCH_FEED_ITEMS = "FETCH_FEED_ITEMS",
	FETCH_SHARED_FEED_ITEMS = "FETCH_SHARED_FEED_ITEMS",
	FETCH_TENANT = "FETCH_TENANT",
	FETCH_CONTENT_CACHE = "FETCH_CONTENT_CACHE",
	FETCH_CONTENT_CACHES = "FETCH_CONTENT_CACHES",
	FETCH_USER_CACHES = "FETCH_USER_CACHES",
	FETCH_USER_GROUPS = "FETCH_USER_GROUPS",
	FETCH_TAGS = "FETCH_TAGS",
	FETCH_DOCUMENTS = "FETCH_DOCUMENTS",
	FETCH_DRAFT_DOCUMENTS = "FETCH_DRAFT_DOCUMENTS",
	FETCH_MEETING = "FETCH_MEETING",
	FETCH_CONFIG = "FETCH_CONFIG",
}

type Payloads = {
	[ActionsEnum.LOADING]: boolean;
	[ActionsEnum.USER_LOADING]: boolean;
	[ActionsEnum.AUTH_SUCCESS]: { token: string; user: IUserModel };
	[ActionsEnum.LOGOUT]: undefined;
	[ActionsEnum.FETCH_BIN]: IBin[];
	[ActionsEnum.FETCH_CURRENT_USER]: IUserModel;
	[ActionsEnum.FETCH_TENANT_USERS]: IUsers;
	[ActionsEnum.FETCH_USERS]: IUsers;
	[ActionsEnum.FETCH_ADMINS]: IUsers;
	[ActionsEnum.FETCH_MEMBERS]: IMembers;
	[ActionsEnum.FETCH_MEMBER]: IMember;
	[ActionsEnum.FETCH_STATS]: IStats;
	[ActionsEnum.FETCH_EVENT_LOGS]: IEventLogs[];
	[ActionsEnum.FETCH_TAGS]: ITag[];
	[ActionsEnum.FETCH_NOTIFICATIONS]: INotification[];
	[ActionsEnum.FETCH_SITEGROUPS]: ISiteGroup[];
	[ActionsEnum.FETCH_GROUPREQUESTS]: IGroupRequest[];
	[ActionsEnum.FETCH_NOTACTIVEUSERS]: IUserModel[];
	[ActionsEnum.FETCH_SITEGROUP]: ISiteGroup;
	[ActionsEnum.FETCH_FEED_ITEM]: IFeedItem | undefined;
	[ActionsEnum.FETCH_SHARED_FEED_ITEMS]: IFeedItem[];
	[ActionsEnum.FETCH_FEED_ITEMS]: IFeedItem[];
	[ActionsEnum.FETCH_TENANT]: any;
	[ActionsEnum.FETCH_CONTENT_CACHE]: IContentCache | null;
	[ActionsEnum.FETCH_CONTENT_CACHES]: IContentCache[];
	[ActionsEnum.FETCH_USER_CACHES]: IUsers;
	[ActionsEnum.FETCH_USER_GROUPS]: ISiteGroup[];
	[ActionsEnum.FETCH_DOCUMENTS]: IDocument[];
	[ActionsEnum.FETCH_DRAFT_DOCUMENTS]: IDocument[];
	[ActionsEnum.FETCH_MEETINGS]: IMeeting[];
	[ActionsEnum.FETCH_MEETING]: IMeeting;
	[ActionsEnum.FETCH_CONFIG]: any;
	[ActionsEnum.FETCH_GROUP_CREATE_REQUESTS]: IGroupCreateRequests[];
};

export type ActionsType = ActionMap<Payloads>[keyof ActionMap<Payloads>];
